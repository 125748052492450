import { Dialog, Grid } from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getUser } from "../../api/Auth";
import { saveDraft } from "../../api/Drafts";
import {
    getForm,
    linkingDocumentsToRequestInBackOffice,
    linkingDocumentsToRequestInSoftExpert,
    registerForm,
    uploadFormDocuments,
} from "../../api/RequestService";
import { getServiceDescription } from "../../api/ServiceDescription";
import CenterLoading from "../../components/CenterLoading/CenterLoading";
import ImportantInformationModal from "../../components/ImportantInformationModal/ImportantInformationModal";
import { SweetAlert } from "../../components/SweetAlert/SweetAlert";
import { HideGlobalLoading, ShowGlobalLoading, UpdateAppSubHeaderTitle } from "../../redux/actions/UiActions";
import { MediumHeightDivider, SmallHeightDivider, StyledCheckCircleIcon, SubTitle, Title } from "../../theme/Styles";
import { arrayArrayToArray, localToArray, transformField } from "../../utilities/functions/ArrayUtil";
import { cleanStringFromNumbers, localToNumber } from "../../utilities/functions/NumberUtil";
import { cleanString } from "../../utilities/functions/StringUtil";
import { isEmpty } from "../../utilities/functions/ValidationUtil";
import Form from "./components/Form/Form";
import { FIELD_TYPES } from "./components/Form/FormConstants";
import PaymentCard from "./components/PaymentCard/PaymentCard";
import {} from "./RequestServiceConstants";
import {
    reverseTransformFormData,
    reverseTransformFormGrid,
    transformFileData,
    transformFormData,
    transformFormGrid,
} from "./RequestServiceUtils";
import { Container, PricesContainer, PricesItemContainer, SuccessContainer } from "./styles/RequestServicesStyles";

function RequestService() {
    const history = useHistory();
    let { serviceID, companyID, companyName, statusID } = useParams();
    const { citizenID, rolBase } = useSelector((state) => state.authReducer);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const successRef = useRef(null);
    const formRef = useRef(null);
    const [showRestoreFormModal, setShowRestoreFormModal] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [draftLoading, setDraftLoading] = useState(false);

    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [priceModalIsOpen, setPriceModalIsOpen] = useState(true);
    const [selectedVariation, setSelectedVariation] = useState();
    const [showRequestDetail, setShowRequestDetail] = useState(false);
    const [successResponse, setSuccessResponse] = useState();
    const [state, setState] = useState({
        rules: [],
        data: {},
        grid: {},
        fakeStep: 0,
        step: 0,
        totalPayment: 0,
        variations: [],
    });

    const { data: userData, isLoading: userDataIsLoading } = useQuery(["userData"], async () => {
        try {
            dispatch(ShowGlobalLoading("Cargando"));
            const response = await getUser();
            dispatch(HideGlobalLoading());
            return response;
        } catch (error) {
            history.push("/public");
            dispatch(HideGlobalLoading());
            throw new Error("An error has ocurred");
        }
    });

    const { data: serviceDescription, isLoading: serviceDescriptionIsLoading } = useQuery(["serviceDescription", serviceID], async () => {
        try {
            dispatch(ShowGlobalLoading("Cargando"));
            const response = await getServiceDescription(serviceID, userData.payload.citizen_id);
            dispatch(HideGlobalLoading());
            return response;
        } catch (error) {
            history.push("/public");
            dispatch(HideGlobalLoading());
            throw new Error("An error has ocurred");
        }
    });

    /* formData have the save_fields and its bugged when you save draft and you move to another screen when you go back get the previously you have saved in cache the save_fields of first call of form
    const { data: formData, isLoading } = useQuery(['serviceForm', serviceDescription?.expertform_id], async () => {
      try {
        dispatch(ShowGlobalLoading("Cargando"));
        const response = await getForm(serviceDescription.expertform_id, userData.payload.citizen_id);
        dispatch(HideGlobalLoading());
        return response;
      } catch (error) {
        history.push('/public');
        dispatch(HideGlobalLoading());
        throw new Error('An error has ocurred');
      }
    }, {
      enabled: serviceDescription != undefined && userData != undefined
    })
  */
    const getAndSetForm = async () => {
        try {
            dispatch(ShowGlobalLoading("Cargando"));
            setIsLoading(true);
            const response = await getForm(serviceDescription.expertform_id, userData.payload.citizen_id);
            setFormData(response);
            setIsLoading(false);
            dispatch(HideGlobalLoading());
        } catch (error) {
            history.push("/public");
            setIsLoading(false);
            dispatch(HideGlobalLoading());
            throw new Error("An error has ocurred");
        }
    };

    useEffect(() => {
        if (serviceDescription != undefined && userData != undefined) {
            getAndSetForm();
        }
    }, [serviceDescription, userData]);

    const handleSelectVariation = (val) => {
        handleModalVisibility();
        setSelectedVariation(val);
    };

    const handleModalVisibility = () => {
        setPriceModalIsOpen(!priceModalIsOpen);
    };

    const handleRestoreFormModal = () => {
        setShowRestoreFormModal(!showRestoreFormModal);
    };

    const handleRestoreForm = () => {
        setShowRestoreFormModal(false);
        setPriceModalIsOpen(false);
        setIsDraft(true);
    };

    const getData = () => {
        //separating response by steps
        const plainData = [];
        const data = formData.fields;
        const _data = [];
        for (let i = 0; i < data.length; i++) {
            const step = data[i];
            let _step = [];
            for (let j = 0; j < step.length; j++) {
                const field = step[j];
                plainData.push(field);
                if (_step.length && field.subtype === "h1") {
                    _data.push(_step);
                    _step = [];
                }
                _step.push(field);
                if (step.length - 1 === j) {
                    _data.push(_step);
                    _step = [];
                }
            }
        }

        const result = {
            formulary_data: formData.formulary_data,
            data: _data.map((step) => step.map(transformField)),
            plainData: plainData.map(transformField),
            saved_fields: formData.saved_fields,
            date: Number(new Date()),
            //dev
            //data: _data.map(step => step.map(transformField)).reverse(),
        };

        return result;
    };

    const handleFormSave = async () => {
        const formData = formRef.current?.saveForm();
        const _plainData = arrayArrayToArray(formData?.localData);
        const request = {
            citizen_id: userData.payload.citizen_id,
            service_id: serviceDescription.id,
            expertform_id: serviceDescription.expertform_id,
            data: transformFormData(formData?.values, _plainData, formData?.errors).filter((field) => field.type !== FIELD_TYPES.file),
            grid: transformFormGrid(formData?.values, _plainData),
            appliedRuleList: localToArray(formData?.appliedRuleList), //Array.from(noDuplicates).reverse(),
            fakeStep: formData?.fakeStep,
            step: formData?.step,
            variations: [selectedVariation?.id],
            totalPayment: selectedVariation.price,
        };
        if (isEmpty(request.data)) {
            return;
        }
        try {
            await saveDraft(request);
        } catch (error) {}
    };

    const sendRequest = async (valuesOfForm) => {
        const formData = formRef.current?.saveForm();
        const _plainData = arrayArrayToArray(formData?.localData);
        dispatch(ShowGlobalLoading("Cargando"));
        try {
            const FilesOfForm = transformFileData(valuesOfForm, _plainData);

            let canSubmitForm = true;
            let uploadedFilesRoutes = FilesOfForm.oldFile;

            const formDataOfFiles = new FormData();
            if (FilesOfForm?.newFile.length > 0) {
                for (let i = 0; i < FilesOfForm.newFile.length; i++) {
                    formDataOfFiles.append("file[]", FilesOfForm.newFile[i].file, FilesOfForm.newFile[i].file.name);
                }
                const uploadFilesConfig = {
                    onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(ShowGlobalLoading(`Subiendo documentos ${percentCompleted}%`));
                    },
                };

                let responseFilesUploaded = await uploadFormDocuments(formDataOfFiles, uploadFilesConfig);
                if (responseFilesUploaded.success) {
                    uploadedFilesRoutes = [
                        ...uploadedFilesRoutes,
                        ...responseFilesUploaded.files.map((item, index) => {
                            return {
                                ...item,
                                label: FilesOfForm.newFile[index].label,
                            };
                        }),
                    ];
                } else {
                    canSubmitForm = false;
                }
            }
            if (canSubmitForm) {
                let canFormContinue = true;
                let documentsArray = [];
                for (let i = 0; i < uploadedFilesRoutes.length; i++) {
                    const file = {
                        documents: [
                            {
                                ...uploadedFilesRoutes[i],
                            },
                        ],
                        process_id: serviceDescription.process_id,
                        names: [uploadedFilesRoutes[i].label],
                        activity_id: serviceDescription.activity_id,
                        new_request: true,
                    };
                    documentsArray.push(file);
                }
                const request = {
                    req: {
                        company_id: companyID,
                        company_name: companyName,
                        service_id: serviceID,
                        doc_identification: userData.payload.citizen_id,
                        name_service: serviceDescription.name,
                        process_flow: serviceDescription.process_flow,
                        form_version: cleanString(getData().formulary_data?.version),
                        payment_amount: localToNumber(selectedVariation.price),
                        payment_status: "1",
                        payment_method: "2",
                        total: localToNumber(selectedVariation.price),
                        variations: [selectedVariation.id],
                        cant: "1",
                        idAutorizacionPortal: "",
                    },
                    form: {
                        citizen_record_id: userData.payload.citizen_id,
                        expertform_id: serviceDescription.expertform_id,
                        data: transformFormData(valuesOfForm, _plainData).filter(
                            (field) => field.type != FIELD_TYPES.file && field.type != FIELD_TYPES.grid
                        ),
                        grid: transformFormGrid(valuesOfForm, _plainData),
                    },
                    documents: documentsArray,
                    userInfo: {
                        provinciasolici: userData.payload.province,
                        municipiosolic: userData.payload.municipality,
                        sectorsolic: userData.payload.sector,
                        horasolicitud: format(new Date(), "HH:mm"),
                        numdocsolicita: userData.payload.citizen_id,
                        tipodocsolicita: 1,
                        nombressolicita: userData.payload.name,
                        apellidossolici: `${userData.payload.first_last_name} ${userData.payload.second_last_name}`,
                        fechasolicitud: format(new Date(), "yyyy-MM-dd"),
                        direccsolic: userData.payload.address,
                        nacionsolic: "Dominicano",
                        celularsolic: cleanStringFromNumbers(userData.payload.phone),
                        emailsolic: userData.payload.email,
                        solicitudonline: 1,
                    },
                };

                dispatch(ShowGlobalLoading("Registrando solicitud"));
                let responseFormSubmit = await registerForm(request);
                if (responseFormSubmit.success) {
                    if (uploadedFilesRoutes.length > 0) {
                        dispatch(ShowGlobalLoading("Procesando solicitud"));
                        if (serviceDescription.send === 1) {
                            //  let uploadSoftExpertArrayAxios = [];
                            for (let i = 0; i < uploadedFilesRoutes.length; i++) {
                                //SOFTEXPERT HAVE A LIMIT OF 25MB AND I REMOVE ALL uploadedFilesRoutes with > 25mb of size
                                if (uploadedFilesRoutes[i]?.size_mb < 25) {
                                    const uploadSoftExpertConfig = {
                                        documents: [
                                            {
                                                ...uploadedFilesRoutes[i],
                                            },
                                        ],
                                        size_mb: uploadedFilesRoutes[i]?.size_mb,
                                        title: responseFormSubmit.title,
                                        record_id: responseFormSubmit.code,
                                        attribute: responseFormSubmit.attributes,
                                        process_id: serviceDescription.process_id,
                                        acronym: responseFormSubmit.acronym,
                                        names: [uploadedFilesRoutes[i].label],
                                        activity_id: serviceDescription.activity_id,
                                        new_request: true,
                                    };
                                    //uploadSoftExpertArrayAxios.push(linkingDocumentsToRequestInSoftExpert(uploadSoftExpertConfig));
                                    await linkingDocumentsToRequestInSoftExpert(uploadSoftExpertConfig);
                                }
                            }
                            //   await axios.all(uploadSoftExpertArrayAxios);
                        }

                        let requestBackOffice = {
                            documents: uploadedFilesRoutes,
                        };
                        let responseBackOffice = await linkingDocumentsToRequestInBackOffice(
                            requestBackOffice,
                            responseFormSubmit.RequestID
                        );
                        if (responseBackOffice.success) {
                            //GOOD
                        } else {
                            canFormContinue = false;
                            enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", { variant: "error" });
                            throw Error;
                        }
                    }

                    if (canFormContinue) {
                        enqueueSnackbar("Solicitud enviada satisfactoriamente.", { variant: "success" });
                        //     history.push(`/app/serviceRequestedDetails/${responseFormSubmit.RequestID}payment`)
                        //queryClient.invalidateQueries('serviceForm')
                        queryClient.invalidateQueries("requestsList");
                        queryClient.invalidateQueries("requestListByCompany");

                        setSuccessResponse(responseFormSubmit);
                        setShowRequestDetail(true);
                        successRef.current.scrollIntoView();
                        setTimeout(() => {
                            // Verificar si los parámetros opcionales están disponibles
                            const optionalParams = [statusID, companyID, companyName]
                                .filter((param) => param !== undefined && param !== null && param !== "")
                                .join("/");

                            // Construir la URL condicionalmente
                            history.push(
                                `/app/serviceRequestedDetails/${responseFormSubmit.RequestID}/${citizenID}/${rolBase}/${optionalParams}`
                            );
                            // history.push(
                            //     `/app/serviceRequestedDetails/${responseFormSubmit.RequestID}/${citizenID}/${rolBase}/${statusID}/${companyID}/${companyName}`
                            // );
                        }, 1000);
                    }
                } else {
                    enqueueSnackbar("Ha ocurrido un error favor intentar mas tarde.", { variant: "error" });
                }
            } else {
                enqueueSnackbar("Ha ocurrido un error subiendo los documentos.", { variant: "error" });
                throw Error;
            }
        } catch (error) {
            enqueueSnackbar("Ha ocurrido un error,contacte al soporte para mas información", { variant: "error" });
        }
        dispatch(HideGlobalLoading());
    };

    //componentDidUpdate
    useEffect(() => {
        if (formData === undefined) {
            return;
        }
        if (localToArray(getData()?.saved_fields?.data).length > 0 && isDraft !== true) {
            setShowRestoreFormModal(true);
            return;
        }
        if (
            !localToArray(getData()?.plainData).length ||
            !getData()?.saved_fields ||
            !localToArray(getData()?.saved_fields?.data).length ||
            isDraft === false
        ) {
            return;
        }

        const { appliedRuleList, data, grid, fakeStep, step, totalPayment, variations } = getData()?.saved_fields;
        dispatch(ShowGlobalLoading("Restableciendo"));
        setDraftLoading(true);
        setState({
            rules: localToArray(appliedRuleList),
            fakeStep: localToNumber(fakeStep),
            data: reverseTransformFormData(data, getData()?.plainData),
            grid: reverseTransformFormGrid(grid, getData()?.plainData),
            step: localToNumber(step),
            totalPayment: totalPayment,
            variations: variations,
        });
        const formPriceVariation = serviceDescription?.prices?.[0]?.variations.find((variation) => variation.id === variations[0]);
        setSelectedVariation(formPriceVariation);
        setTimeout(() => {
            //Simulate loading for 2.5s
            //Bug with react 17 for update somes components value is needed unmount and mount the Form component
            setDraftLoading(false);
            dispatch(HideGlobalLoading());
        }, 2500);

        return () => {};
    }, [formData, isDraft]);

    useLayoutEffect(() => {
        //UPDATE APP HEADER SUBTITLE
        dispatch(UpdateAppSubHeaderTitle(serviceDescription?.name));
    }, [serviceDescription]);

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
            return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
        };

        const alertUser = (event) => {
            SweetAlert.fire("Advertencia", "Si cierra esta pestaña el proceso de la solicitud se vera afectado", "warning");
            event.preventDefault();
            event.returnValue = "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
            return "Si cierra esta pestaña el proceso de la solicitud se vera afectado";
        };
        window.addEventListener("beforeunload", alertUser);
        window.addEventListener("unload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
            window.removeEventListener("unload", unloadCallback);
        };
    }, []);

    if (isLoading || serviceDescriptionIsLoading || userDataIsLoading) return <CenterLoading />;
    return (
        <Container>
            <SmallHeightDivider />
            <SmallHeightDivider />
            {!showRequestDetail ? (
                <Container>
                    {draftLoading && selectedVariation === undefined ? null : (
                        <Form
                            ref={formRef}
                            doRequest={sendRequest}
                            data={getData().data}
                            plainData={getData().plainData}
                            setPriceModalIsOpen={setPriceModalIsOpen}
                            handleFormSave={handleFormSave}
                            multipleDocuments={serviceDescription?.multiple_document === "true" ? true : false}
                            initialForm={state}
                            variations={[selectedVariation?.id]}
                            isDraft={isDraft}
                        />
                    )}
                    <ImportantInformationModal
                        open={showRestoreFormModal}
                        onBackDropClick={() => {}}
                        onCloseClick={handleRestoreFormModal}
                        CloseTitle="Cancelar"
                        CloseButton
                        buttonTitle="Confirmar"
                        buttonClick={handleRestoreForm}
                        content={
                            <Fragment>
                                <strong>Se ha encontrado información previa de una solicitud sin terminar.</strong>
                                <br />
                                <strong>
                                    <p>¿Desea cargarla para esta solicitud?</p>
                                </strong>
                            </Fragment>
                        }
                    />
                    <Dialog
                        keepMounted
                        sx={{ zIndex: 1000 }}
                        disableEscapeKeyDown
                        open={priceModalIsOpen}
                        onClose={handleModalVisibility}
                        maxWidth="xl"
                        fullScreen
                    >
                        <PricesContainer>
                            <Title>Tarifas del servicio</Title>
                            <SmallHeightDivider />
                            <SmallHeightDivider />
                            <Grid
                                alignItems="end"
                                alignSelf="center"
                                justifyContent="space-around"
                                container
                                direction="row"
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {serviceDescription.prices.map((price, index) =>
                                    price.variations.length > 1 ? (
                                        <Grid key={index} item xs={4} sm={8} md={4}>
                                            <PricesItemContainer>
                                                <PaymentCard
                                                    title={price.concept}
                                                    variations={price.variations}
                                                    onClick={handleSelectVariation}
                                                />
                                            </PricesItemContainer>
                                        </Grid>
                                    ) : (
                                        <Grid key={index} item xs={4} sm={8} md={4}>
                                            <PricesItemContainer>
                                                <PaymentCard
                                                    title={price.variations[0].concept}
                                                    variations={price.variations}
                                                    onClick={handleSelectVariation}
                                                />
                                            </PricesItemContainer>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </PricesContainer>
                    </Dialog>
                </Container>
            ) : (
                <Container ref={successRef}>
                    <SuccessContainer>
                        <StyledCheckCircleIcon />
                        <SmallHeightDivider />
                        <SubTitle>Solicitud enviada satisfactoriamente.</SubTitle>
                        <MediumHeightDivider />
                    </SuccessContainer>
                    {/*
              <ButtonsContainer>
              <div style={{ width: '30%' }}>
                <StyledButtonOutlined onClick={() => history.push('/app/myDesk')} variant="outlined">
                  Ir a inicio mi escritorio
                </StyledButtonOutlined>

              </div>
              <div style={{ width: '30%' }}>
                <StyledButton onClick={() => history.push(`/app/serviceRequestedDetails/${successResponse.RequestID}`)}>
                  Ver detalle de la solicitud
                </StyledButton>
              </div>
            </ButtonsContainer>
                */}
                </Container>
            )}
        </Container>
    );
}

export default RequestService;
