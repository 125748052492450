import DateFnsUtils from "@date-io/date-fns";
import { Global } from "@emotion/react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Zoom from "@mui/material/Zoom";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Auth from "./auth/Auth";
import { cacheConfig } from "./cacheConfig";
import GlobalLoading from "./components/GlobalLoading/GlobalLoading";
import { persistor, Store } from "./redux/store/Store";
import Layouts from "./router/Layouts";
import Router from "./router/Router";

//ACCESSIBILITY IS A SCRIPT INSIDE OF public/index.html

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // 24 hours / 1 day time of data from server is considered fresh
            staleTime: cacheConfig.staleTimeGeneral,
            cacheTime: cacheConfig.cacheTime,
        },
    },
});

function App() {
    return (
        <Provider store={Store}>
            <PersistGate loading={null} persistor={persistor}>
                <Global
                    styles={`
          body {
            margin: 0;
          }
        `}
                />
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        TransitionComponent={Zoom}
                    >
                        <GlobalLoading />
                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <BrowserRouter>
                                <Auth>
                                    <Router routes={Layouts} />
                                </Auth>
                            </BrowserRouter>
                        </LocalizationProvider>
                    </SnackbarProvider>
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
