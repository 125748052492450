import React, { useEffect } from "react";
import { MediumHeightDivider } from "../../../../../theme/Styles";
import { useQuery } from "react-query";
import { getAllRepresentativeAndApplicant } from "../../../../../api/myCompany";
import ListCompanyIBelongTo from "./ListCompanyIBelongTo";
import { customColumnCompany } from "../CompanyConstants";

export default function RepresentativeApplicantCompany() {
  const { data } = useQuery(["allRepresentativeApplicantData"], () => getAllRepresentativeAndApplicant());

  return (
    <>
      <MediumHeightDivider />
      <div
        style={{
          width: "100%",
        }}
      >
        <ListCompanyIBelongTo
          data={data?.payload}
          title="Empresas"
          columns={customColumnCompany}
          withoutRecord="En estos momento no existe empresas asociada a su cuenta."
        />
      </div>
    </>
  );
}
