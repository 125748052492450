import { CLEAN_URL, SET_URL } from "../actions/UrlActions";

const initialState = {
    url: "",
};

const UrlReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_URL:
            return {
                ...state,
                url: action.payload, // Cambiado para simplificar si solo pasas la URL
            };
        case CLEAN_URL:
            return {
                ...state,
                url: "",
            };
        default:
            return state;
    }
};

export default UrlReducer;
