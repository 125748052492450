import React, { Fragment, useState } from "react";
import {
  MediumHeightDivider,
  Row,
  SmallHeightDivider,
  StyledButtonOutlined,
} from "../../../../../theme/Styles";
import CompanyListRespresentatives from "./CompanyListRepresentatives";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteRepresentativeAndApplicantByCompany,
  getAllRepresentativeByCompany,
  searchUserByCitizenId,
  sendInviteUser,
} from "../../../../../api/myCompany";
import { customColumnCompanyListRepresentatives, FormCompanySendInviteSchema } from "../CompanyConstants";
import DialogSearch from "./DialogSearch";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { ROLE_TYPE } from "../../../../../constants/constants";
import { ColumnContainer } from "../../../../../components/RequestCard/styles/RequestCardStyles";
import ModalListMemberByCompany from "./ModalListMemberByCompany";
import { useSelector } from "react-redux";
import COLORS from "../../../../../theme/Colors";

export default function OwnerCompany() {
  const queryClient = useQueryClient();
  const { data } = useQuery(["allRepresentativeData"], () => getAllRepresentativeByCompany());
  const { companyID } = useSelector((state) => state.authReducer);

  const [claimModalVisible, setClaimModalVisible] = useState(false);
  const [searchUser, setSearchUser] = useState(null);
  const [isModalListMembersVisible, setIsModalListMembersVisible] = useState(false);

  const mutationSearchUserById = useMutation(searchUserByCitizenId);
  const mutationSendInviteUser = useMutation(sendInviteUser);
  const mutationDeleteRepresentative = useMutation(deleteRepresentativeAndApplicantByCompany);

  const searchUserById = (formData) => {
    mutationSearchUserById.mutate(formData, {
      onSuccess: (data) => {
        if (data.success) {
          setSearchUser(data.payload);
          // refresh cache of searchUserId
          queryClient.invalidateQueries("searchUserId");
        } else {
          Swal.fire({
            title: "Información",
            text: data.msg,
            icon: "info",
          });
        }
      },
    });
  };

  const inviteUser = (formData) => {
    mutationSendInviteUser.mutate(formData, {
      onSuccess: (data) => {
        if (data.success) {
          Swal.fire({
            title: "Información",
            text: data.msg,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              setSearchUser(undefined);

              handleClaimModalVisibility();
            }
          });
          // refresh cache of inviteUser
          queryClient.invalidateQueries("inviteUser");
        } else {
          Swal.fire({
            title: "Información",
            text: data.msg,
            icon: "info",
          });
        }
      },
    });
  };

  const sendInviteFormik = useFormik({
    initialValues: {
      citizen_id: "",
    },
    validationSchema: FormCompanySendInviteSchema,
    onSubmit: (values) => {
      searchUserById(values.citizen_id);
    },
  });

  const handleClaimModalVisibility = () => {
    if (data?.payload) {
      Swal.fire({
        title: "Información",
        text: "Para agregar un nuevo representante a su empresa debe de eliminar el representante actual.",
        icon: "info",
      });
      return;
    }
    setSearchUser(null);
    sendInviteFormik.resetForm();
    setClaimModalVisible(!claimModalVisible);
  };

  const deleteFoundUser = () => {
    setSearchUser(null);
    sendInviteFormik.resetForm();
  };

  const handleSendInviteUser = () => {
    inviteUser({ citizenId: searchUser.citizen_id });
  };

  const deleteRepresentative = (citizen_id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: `Eliminarás al representante ${citizen_id}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: COLORS.secondary,
      cancelButtonColor: COLORS.primary,
      confirmButtonText: "Si, borralo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        mutationDeleteRepresentative.mutate(
          { member_rol: ROLE_TYPE.REPRESENTATIVE, citizen_id: citizen_id },
          {
            onSuccess: (data) => {
              if (data.success) {
                Swal.fire({
                  title: "Información",
                  text: data.msg,
                  icon: "info",
                });
                // refresh cache of allRepresentativeData
                queryClient.invalidateQueries("allRepresentativeData");
              } else {
                Swal.fire({
                  title: "Información",
                  text: data.msg,
                  icon: "info",
                });
              }
            },
          }
        );
      }
    });
  };

  const handleModalListMember = () => {
    setIsModalListMembersVisible(!isModalListMembersVisible);
  };

  return (
    <>
      <MediumHeightDivider />
      <Row style={{ justifyContent: "space-between", marginBottom: " 5px" }}>
        <div
          style={{
            alignItems: "center",
            width: "75%",
            display: " flex",
            flexDirection: "column",
          }}
        ></div>
        <ColumnContainer>
          <div style={{ width: "300px", marginBottom: " 1rem" }}>
            <StyledButtonOutlined onClick={handleClaimModalVisibility} variant="contained">
              Agregar representante
            </StyledButtonOutlined>
          </div>
          <div style={{ width: "300px", marginBottom: " 1rem" }}>
            <StyledButtonOutlined onClick={handleModalListMember} variant="contained">
              Ver miembros
            </StyledButtonOutlined>
          </div>
        </ColumnContainer>
      </Row>
      <SmallHeightDivider />
      <div
        style={{
          width: "100%",
        }}
      >
        <CompanyListRespresentatives
          myRepresentatives={data?.payload}
          title="Representante"
          columns={customColumnCompanyListRepresentatives}
          withoutRecord="Favor agregar representantes para su empresa."
          onDelete={deleteRepresentative}
        />
      </div>

      <DialogSearch
        claimModalVisible={claimModalVisible}
        handleClaimModalVisibility={handleClaimModalVisibility}
        formik={sendInviteFormik}
        searchUser={searchUser}
        deleteFoundUser={deleteFoundUser}
        onClick={handleSendInviteUser}
        title="Invitar representante"
        sendButtonLabel={"ENVIAR SOLICITUD"}
      />
      <ModalListMemberByCompany
        detailCompanyActive={false}
        dialogSelectActive={false}
        companyID={companyID}
        isModalListMembersVisible={isModalListMembersVisible}
        setIsModalListMemberVisible={setIsModalListMembersVisible}
      />
    </>
  );
}
