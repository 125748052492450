import * as yup from 'yup';
import { cedulaValidationService, validateEmail, validatePhone, validationNameWithIdentificationID } from '../../../api/RenderField';

export const FormSchema = ({ setIsValidatingCedula, isValidatingEmail, setIsValidatingEmail, isValidated, setIsValidated }) => [
    {
        step: 1,
        validations: yup
            .object({
                email: yup
                    .string()
                    .email('Ingrese un email válido')
                    .required('Este campo es requerido')
                    .test('checkEmailExists', 'Este correo electrónico ya está en uso', async function (value, context) {
                        if (value) {
                            const response = await validateEmail(value);
                            if (!response?.success) {
                                return this.createError({ message: 'Correo electrónico en uso, favor introduzca otro correo electrónico' });
                            }
                        }

                        return true;
                    }),
                emailConfirmation: yup
                    .string()
                    .email('Ingrese un email válido')
                    .oneOf([yup.ref('email'), null], 'Los correos no coinciden')
                    .required('Este campo es requerido'),
                password: yup.string().min(6, 'La contraseña debe tener minimo 6 caracteres').required('Este campo es requerido'),
                password_confirmation: yup
                    .string()
                    .min(6, 'La contraseña debe tener minimo 6 caracteres')
                    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
                    .required('Este campo es requerido')
            })
            .required()
    },
    {
        step: 2,
        validations: yup
            .object({
                document_type: yup.string().required('Este campo es requerido'),
                citizen_id: yup
                    .string()
                    .required('Este campo es requerido')
                    .test('checkCitizenIdExists', 'Documento de identidad no válida, introduzca otro documento', async function (value, context) {
                        const { document_type } = context.parent;
                        if (value && !isValidated) {
                            setIsValidatingCedula(true);
                            const response = await cedulaValidationService(value, document_type);
                            if (response?.exist) {
                                setIsValidatingCedula(false);
                                return this.createError({ message: 'Ya existe un usuario con este documento de identidad' });
                            }
                            setIsValidated(true);
                            setIsValidatingCedula(false);
                        }
                        return true;
                    }),
                name: yup.string().when('citizen_id', {
                    is: () => !isValidated,
                    then: yup.string().test('cedulaRequired', 'Debe validar el documento de identidad antes de ingresar el nombre', function () {
                        return isValidated;
                    }),
                    otherwise: yup
                        .string()
                        .required('Este campo es requerido')
                        .test('checkNombreExists', 'El nombre ya existe', async function (value, context) {
                            const { citizen_id, document_type } = context.parent; // Accede al valor de cedula desde el contexto
                            if (value && citizen_id) {
                                const response = await validationNameWithIdentificationID({
                                    citizen_id: citizen_id,
                                    citizen_first_last_name: value,
                                    document_type
                                });
                                if (!response?.success) {
                                    return this.createError({ message: response?.msg });
                                }
                            }
                            return true;
                        })
                }),
                first_last_name: yup.string().required('Este campo es requerido'),
                second_last_name: yup.string(),
                occupation: yup.string(),
                province_id: yup.string().required('Este campo es requerido'),
                municipality_id: yup.string().required('Este campo es requerido'),
                sector_id: yup.string().required('Este campo es requerido'),
                phone: yup.string().required('Este campo es requerido'),
                phone2: yup.string().notOneOf([yup.ref('phone')], 'El teléfono secundario no puede ser igual al teléfono primario'),
                email2: yup.string().email('Ingrese un email valido'),
                address: yup.string().required('Este campo es requerido')
            })
            .required()
    },
    {
        step: 3,
        validations: yup
            .object({
                first_citizen_question: yup.number().required('Este campo es requerido'),
                first_citizen_answer: yup.string().required('Este campo es requerido'),
                second_citizen_question: yup.number().required('Este campo es requerido'),
                second_citizen_answer: yup.string().required('Este campo es requerido'),
                third_citizen_question: yup.number().required('Este campo es requerido'),
                third_citizen_answer: yup.string().required('Este campo es requerido')
            })
            .required()
    },
    {
        step: 4,
        validations: yup
            .object({
                termsAndCondition: yup.boolean().oneOf([true], 'Debe aceptar los terminos y condiciones de uso y privacidad para continuar')
            })
            .required()
    }
];

export const FormSchemaCompany = ({
    setIsValidatingCedula,
    isValidatingEmail,
    setIsValidatingEmail,
    isValidated,
    setIsValidated,
    isValidatingPhone,
    setIsValidatingPhone
}) => [
    {
        step: 1,
        validations: yup
            .object({
                email: yup
                    .string()
                    .email('Ingrese un email valido')
                    .required('Este campo es requerido')
                    .test('checkEmailExists', 'Este correo electrónico ya está en uso', async function (value) {
                        if (value) {
                            const response = await validateEmail(value);
                            if (!response?.success) {
                                return this.createError({ message: 'Correo electrónico en uso, favor introduzca otro correo electrónico' });
                            }
                        }
                        return true;
                    }),
                emailConfirmation: yup
                    .string()
                    .email('Ingrese un email valido')
                    .oneOf([yup.ref('email'), null], 'Los correos no coinciden')
                    .required('Este campo es requerido'),
                password: yup.string().min(6, 'La contraseña debe tener minimo 6 caracteres').required('Este campo es requerido'),
                password_confirmation: yup
                    .string()
                    .min(6, 'La contraseña debe tener minimo 6 caracteres')
                    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
                    .required('Este campo es requerido')
            })
            .required()
    },
    {
        step: 2,
        validations: yup
            .object({
                document_type: yup.string().required('Este campo es requerido'),
                citizen_id: yup
                    .string()
                    .required('Este campo es requerido')
                    .min(9, 'Cantidad de caracteres incorrecto')
                    .test('checkRNCExists', 'RNC inválido, introduzca otro RNC', async function (value) {
                        if (value && !isValidatingEmail) {
                            setIsValidatingCedula(true);
                            const response = await validationNameWithIdentificationID({
                                citizen_id: value,
                                validateWithoutJCE: true
                            });
                            if (!response?.success) {
                                setIsValidatingCedula(false);
                                return this.createError({ message: response?.msg });
                            }
                            setIsValidated(true);
                            setIsValidatingCedula(false);
                        }
                        return true;
                    }),
                name: yup.string().required('Este campo es requerido'),
                extension: yup.string(),
                razonsocial: yup.string().required('Este campo es requerido'),
                phone: yup
                    .string()
                    .required('Este campo es requerido')
                    .min(10, 'Cantidad de caracteres incorrecto')
                    .test('checkPhoneExists', 'Teléfono inválido, introduzca otro teléfono', async function (value) {
                        if (value && !isValidatingPhone) {
                            const response = await validatePhone(value);
                            if (!response?.success) {
                                return this.createError({ message: 'Teléfono en uso, favor introduzca otro teléfono' });
                            } else {
                                setIsValidatingPhone(true);
                            }
                        }
                        return true;
                    }),
                docMercantil: yup.object().shape({
                    files: yup.array().min(1).required('Este campo es requerido')
                }),
                docNotorial: yup.object().shape({
                    files: yup.array().min(1).required('Este campo es requerido')
                }),
                docCitizenID: yup.object().shape({
                    files: yup.array().min(1).required('Este campo es requerido')
                })
            })
            .required()
    },
    {
        step: 3,
        validations: yup
            .object({
                first_citizen_question: yup.number().required('Este campo es requerido'),
                first_citizen_answer: yup.string().required('Este campo es requerido'),
                second_citizen_question: yup.number().required('Este campo es requerido'),
                second_citizen_answer: yup.string().required('Este campo es requerido'),
                third_citizen_question: yup.number().required('Este campo es requerido'),
                third_citizen_answer: yup.string().required('Este campo es requerido')
            })
            .required()
    },
    {
        step: 4,
        validations: yup
            .object({
                termsAndCondition: yup.boolean().oneOf([true], 'Debe aceptar los terminos y condiciones de uso y privacidad para continuar')
            })
            .required()
    }
];

export const RegisterSteps = [
    {
        title: 'Información de Autenticación',
        elements: ['email', 'emailConfirmation', 'password', 'password_confirmation']
    },
    {
        title: 'Datos Personales',
        elements: [
            'document_type',
            'citizen_id',
            'name',
            'first_last_name',
            'second_last_name',
            'occupation',
            'province_id',
            'municipality_id',
            'sector_id',
            'phone',
            'phone2',
            'email2',
            'address'
        ]
    },
    {
        title: 'Información de seguridad',
        elements: [
            'first_citizen_question',
            'first_citizen_answer',
            'second_citizen_question',
            'second_citizen_answer',
            'third_citizen_question',
            'third_citizen_answer'
        ]
    },
    {
        title: 'Términos y condiciones',
        elements: ['termsAndCondition']
    },
    {
        title: 'Activación de cuenta'
    }
];

export const RegisterCompanySteps = [
    {
        title: 'Información de Autenticación',
        elements: ['email', 'emailConfirmation', 'password', 'password_confirmation']
    },
    {
        title: 'Datos Empresa',
        elements: ['document_type', 'citizen_id', 'name', 'phone', 'extension', 'razonsocial']
    },
    {
        title: 'Información de seguridad',
        elements: [
            'first_citizen_question',
            'first_citizen_answer',
            'second_citizen_question',
            'second_citizen_answer',
            'third_citizen_question',
            'third_citizen_answer'
        ]
    },
    {
        title: 'Términos y condiciones',
        elements: ['termsAndCondition']
    },
    {
        title: 'Activación de cuenta'
    }
];

export const identificationTypes = [
    {
        value: 1,
        label: 'Cédula'
    },
    {
        value: 2,
        label: 'Pasaporte'
    }
];
