import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import OwnerCompany from "./components/OwnerCompany";
import RepresentativeApplicantCompany from "./components/RepresentativeApplicantCompany";

function Companies({ rolBase }) {
  return (
    <Fade right>
      <Fragment>
        {rolBase === 2 ? <OwnerCompany /> : <RepresentativeApplicantCompany />},
      </Fragment>
    </Fade>
  );
}

export default Companies;
