import { styled } from '@mui/system';
import COLORS from '../../../../../theme/Colors';
import Button from '@mui/material/Button';
import { boxShadowConfig } from '../../../../../theme/Styles';
import FONTS_FAMILY from '../../../../../theme/FontsFamily';
import VisibilityIcon from '@mui/icons-material/Visibility';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Tab } from '@mui/material';
import { TabList } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';

export const SectionLink = styled(Button)({
    margin: 0,
    color: COLORS.primary,
    fontFamily: FONTS_FAMILY.regular.body,
    textAlign: 'left',
    textTransform: 'none',
    '@media (min-width:320px)': {
        fontSize: '11px',
    },
    '@media (min-width:768px)': {
        fontSize: '12px',
    },
    '@media (min-width:1200px)': {
        fontSize: '12px',
    },
    '&:hover': {
        textDecoration: 'underline'
    }
});
export const ContainerCompanies = styled('div')(props => ({
    display: 'flex',
    flexDirection: 'column',
    //  backgroundColor: COLORS.white,
    width: '100%',
    boxShadow: props.disableCardStyle ? null : boxShadowConfig,
    marginBottom: '45px',
}));

export const RowContainer = styled('div')({
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const Column = styled('div')({
    display: 'inline-block',
    flexDirection: 'column',
    textAlign: 'left',
    alignItems: 'flex-start',
    alignSelf: 'center',
    wordWrap: 'break-word',
    padding: ' 2px'
});
export const BodyText = styled('p')({
    margin: 0,
    marginTop: '1%',
    color: COLORS.black,
    fontFamily: FONTS_FAMILY.regular.body,
    textAlign: 'start',
    '@media (min-width:320px)': {
        fontSize: '10px',
    },
    '@media (min-width:768px)': {
        fontSize: '13px',
    },
    '@media (min-width:1200px)': {
        fontSize: '15px',
    }
});
export const ProfileImage = styled('img')({
    alignSelf: 'center',
    '@media (min-width:320px)': {
        width: '100px',
        height: '100px',
        borderRadius: '100%',
        marginTop: '10px',
    },
    '@media (min-width:768px)': {
        width: '125px',
        height: '150px',
        borderRadius: 0,
        marginTop: 0,
    },
    '@media (min-width:1200px)': {
        width: '150px',
        height: '150px',
        borderRadius: 0,
        marginTop: 0,
    },
});

export const ProfileContainer = styled('div')({
    display: 'flex',
    width: '100%',
    //boxShadow: boxShadowConfig,
    '@media (min-width:320px)': {
        flexDirection: 'column',
        // minHeight: '250px',
        justifyContent: 'center',
    },
    '@media (min-width:768px)': {
        flexDirection: 'row',
        minHeight: '150px',
        justifyContent: 'flex-start',
    },
    '@media (min-width:1200px)': {
        flexDirection: 'row',
        height: '150px',
        justifyContent: 'flex-start',
    },

});
export const LineDivider = styled('div')({
    width: '100%',
    display: 'flex',
    height: '1px',
    marginTop: '10px',
    backgroundColor: COLORS.grayPlaceholder
});
export const ContentContainer = styled('div')(props => ({
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
}))

export const StyledDescriptionIcon = styled(VisibilityIcon)({
    color: COLORS.tertiary,
    cursor: 'pointer',
    '@media (min-width:320px)': {
        fontSize: '20px',
    },
    '@media (min-width:768px)': {
        fontSize: '23px',
    },
    '@media (min-width:1200px)': {
        fontSize: '25px',
    }
});

export const ButtonContainer = styled('div')({
    '@media (min-width:320px)': {
        width: '30%',
    },
    '@media (min-width:768px)': {
        width: '30%',
    },
    '@media (min-width:1200px)': {
        width: '30%',
    }
});


export const StyledCheckBoxIcon = styled(SettingsOutlinedIcon)({
    color: COLORS.tertiary,
    '@media (min-width:320px)': {
        fontSize: '18px',
    },
    '@media (min-width:768px)': {
        fontSize: '20px',
    },
    '@media (min-width:1200px)': {
        fontSize: '23px',
    }
});
export const StyledSettingIcon = styled(SettingsOutlinedIcon)({
    color: COLORS.tertiary,
    cursor: 'pointer',
    '@media (min-width:320px)': {
        fontSize: '20px',
    },
    '@media (min-width:768px)': {
        fontSize: '23px',
    },
    '@media (min-width:1200px)': {
        fontSize: '25px',
    }
});

export const StyledClearIcon = styled(ClearIcon)({
    color: COLORS.primary,
    cursor: 'pointer',
    '@media (min-width:320px)': {
        fontSize: '20px',
    },
    '@media (min-width:768px)': {
        fontSize: '23px',
    },
    '@media (min-width:1200px)': {
        fontSize: '25px',
    }
});

export const ImageLogo = styled('img')({
    color: COLORS.tertiary,
    cursor: 'pointer',
    '@media (min-width:320px)': {
        width: '20px',
    },
    '@media (min-width:768px)': {
        width: '23px',
    },
    '@media (min-width:1200px)': {
        width: '25px',

    }
});
export const Header = styled('div')({
    flexGrow: 1,
    backgroundColor: COLORS.primary,

});
export const StyledTabList = styled(TabList)({
    '&.MuiTabs-root': {
        '&.MuiTabs-indicator': {
            'backgroundColor': `${COLORS.primary} !important`
        },
    },
    '&.MuiTabs-indicator': {
        'backgroundColor': `${COLORS.primary} !important`
    },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontSize: '20px',
        fontFamily: FONTS_FAMILY.medium.body,
        fontWeight: 'bold',
        marginRight: '5px',
        color: COLORS.white,
        // backgroundColor:COLORS.snow,
        // borderRadius:'30px',
        '&:hover': {
            color: COLORS.white,
            backgroundColor: COLORS.primary,
            borderBottom: `5px solid${COLORS.primary} `
        },
        '&.Mui-selected': {
            color: COLORS.white,
            backgroundColor: COLORS.primary,
            borderBottom: `4px solid${COLORS.secondary} `
        },
        '&.Mui-focusVisible': {
            backgroundColor: COLORS.primary
        },
    }),
);

