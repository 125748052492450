import { Grid, Rating } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import { getAllRepresentativeAndApplicant } from "../../api/myCompany";
import { getServiceCommentsAndRating, getServiceDescription } from "../../api/ServiceDescription";
import CenterLoading from "../../components/CenterLoading/CenterLoading";
import LoginOrRegisterModal from "../../components/LoginOrRegisterModal/LoginOrRegisterModal";
import ServiceDirectoryMenu from "../../components/ServiceDirectoryMenu/ServiceDirectoryMenu";
import TextInformation from "../../components/TextInformation/TextInformation";
import { ROLE_TYPE } from "../../constants/constants";
import { HideGlobalLoading, ShowGlobalLoading, UpdateAppSubHeaderTitle } from "../../redux/actions/UiActions";
import COLORS from "../../theme/Colors";
import {
    BodyText,
    FairBodyText,
    MediumHeightDivider,
    Row,
    RowBodyDivider,
    SmallHeightDivider,
    StyledDownloadIcon,
    StyledPagination,
} from "../../theme/Styles";
import { hourIn24To12hours, priceVariationToLaborableTime } from "../../utilities/functions/FormatterUtil";
import DialogSelect from "../AllServices/components/DialogSelect";
import Comment from "./components/Comment/Comment";
import {
    CardPriceGray,
    CardPriceTitle,
    CommentsContainer,
    Container,
    PaginationContainer,
    PriceContainer,
    StyledEmailIcon,
    StyledFab,
    StyledFacebookIcon,
    StyledPrintIcon,
    StyledTwitterIcon,
    TextListContainer,
    TextOrderedListContainer,
    TopContainer,
    TopItemContainer,
    VariationsContainer,
} from "./styles/ServiceDescriptionStyles";

function ServiceDescription() {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    let { serviceID } = useParams();
    const dispatch = useDispatch();
    const { authenticated, rolBase } = useSelector((state) => state.authReducer);
    const componentToPrintRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [loginOrRegisterModalStatus, setLoginOrRegisterModalStatus] = useState(false);
    const [currentCommentsPage, setCurrentCommentsPage] = useState(1);

    const { data: serviceDescription, isLoading } = useQuery(["serviceDescription", serviceID], async () => {
        try {
            dispatch(ShowGlobalLoading("Cargando"));
            const response = await getServiceDescription(serviceID);
            dispatch(HideGlobalLoading());
            return response;
        } catch (error) {
            history.push("/public");
            dispatch(HideGlobalLoading());
        }
    });
    const { data: serviceComments, isLoading: serviceCommentsIsLoading } = useQuery(
        ["serviceComments", serviceID, currentCommentsPage],
        () => getServiceCommentsAndRating(serviceID, currentCommentsPage)
    );
    const { data: allCompanyImIn, isLoading: allCompanyImInLoading } = useQuery(["allRepresentativeApplicantData"], () =>
        getAllRepresentativeAndApplicant()
    );

    const handleServiceRequest = () => {
        if (!selectedCompany) {
            history.push(`/app/requestService/${serviceID}`);
        } else {
            const [company_id, company_name] = selectedCompany?.split(",");
            history.push(`/app/requestService/${serviceID}/${company_id}/${company_name}`);
        }

        setOpen(!open);
    };

    const reactToPrintContent = useCallback(() => {
        return componentToPrintRef.current;
    }, [componentToPrintRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: serviceDescription?.name,
        pageStyle: "width:400px",
        removeAfterPrint: true,
    });

    const handleChangePage = (page) => {
        setCurrentCommentsPage(page);
    };

    const handleOpenModal = (id) => {
        if (rolBase == ROLE_TYPE.COMPANY) {
            Swal.fire({
                title: "Información",
                text: "No puede realizar una solicitud directamente, debe ser a través de un representante",
                icon: "warning",
            });
            return;
        }
        if (authenticated) {
            if (companies?.length == 0) {
                history.push(`/app/requestService/${id}`);
                return;
            }
            setSelectedCompany(null);
            setOpen(!open);
        } else {
            setLoginOrRegisterModalStatus(!loginOrRegisterModalStatus);
        }
    };

    useLayoutEffect(() => {
        if (serviceDescription?.success == false) {
            //SERVICE DONT EXIST REDIRECT TO PUBLIC
            history.push("/public");
        }
        if (serviceDescription != undefined) {
            //UPDATE APP HEADER SUBTITLE
            dispatch(UpdateAppSubHeaderTitle(serviceDescription.name));
        }
    }, [serviceDescription]);

    useEffect(() => {
        if (allCompanyImIn?.payload) {
            const companies = allCompanyImIn.payload.map((x) => {
                return { company_name: x.company_name, id: x.id };
            });
            setCompanies(companies);
        }
    }, [allCompanyImIn]);

    if (isLoading || serviceCommentsIsLoading || allCompanyImInLoading) return <CenterLoading />;

    return (
        <Container>
            <LoginOrRegisterModal
                open={loginOrRegisterModalStatus}
                onBackDropClick={() => setLoginOrRegisterModalStatus(false)}
                onCloseClick={() => setLoginOrRegisterModalStatus(false)}
            />
            <Row>
                {matchesWidth && (
                    <Fragment>
                        <ServiceDirectoryMenu />
                        <RowBodyDivider />
                    </Fragment>
                )}
                <Container ref={componentToPrintRef} style={{ width: "100%" }}>
                    <TopContainer>
                        <TopItemContainer>
                            <strong>
                                <BodyText>Rating</BodyText>
                            </strong>
                            <Rating value={serviceDescription?.rating} precision={0.5} readOnly size="large" />
                        </TopItemContainer>

                        <TopItemContainer>
                            <strong>
                                <BodyText>Imprimir</BodyText>
                            </strong>
                            <IconButton onClick={handlePrint}>
                                <StyledPrintIcon />
                            </IconButton>
                        </TopItemContainer>

                        <TopItemContainer>
                            <strong>
                                <BodyText>Compartir</BodyText>
                            </strong>
                            <Row>
                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `http://www.facebook.com/sharer.php?u=${window.location.href}`,
                                            "name",
                                            "width=600,height=400"
                                        )
                                    }
                                >
                                    <StyledFacebookIcon />
                                </IconButton>

                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `https://twitter.com/share?text=Ahora es mas facil solicitar trámites con el Portal de trámites de MITUR😁&url=${window.location.href}&hashtags=MinisterioDeTurismo,Mitur`,
                                            "name",
                                            "width=600,height=400"
                                        )
                                    }
                                >
                                    <StyledTwitterIcon />
                                </IconButton>

                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `mailto:?subject=Ahora es mas facil solicitar trámites con el Portal de trámites de MITUR😁&body=${window.location.href}`
                                        )
                                    }
                                >
                                    <StyledEmailIcon />
                                </IconButton>
                            </Row>
                        </TopItemContainer>
                    </TopContainer>

                    <TextInformation title="Información general" content={serviceDescription?.description} />

                    <SmallHeightDivider />
                    <SmallHeightDivider />

                    <TextInformation
                        title="Horario de prestación de servicio"
                        content={
                            "Las solicitudes podrán ser realizadas 24hrs los 7 días de la semana, pero estas serán trabajadas por la institución en horario laborable."
                        }
                    />
                    <SmallHeightDivider />

                    <Row style={{ justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <strong>
                                <CardPriceGray>Día</CardPriceGray>
                            </strong>
                            <br />
                            {serviceDescription?.schedules.map((schedule, index) => (
                                <strong>
                                    <BodyText>{schedule.day}</BodyText>
                                </strong>
                            ))}
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <strong>
                                <CardPriceGray>Desde</CardPriceGray>
                            </strong>
                            <br />
                            {serviceDescription?.schedules.map((schedule, index) => (
                                <strong>
                                    <BodyText>{hourIn24To12hours(schedule.init)}</BodyText>
                                </strong>
                            ))}
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <strong>
                                <CardPriceGray>Hasta</CardPriceGray>
                            </strong>
                            <br />
                            {serviceDescription?.schedules.map((schedule, index) => (
                                <strong>
                                    <BodyText>{hourIn24To12hours(schedule.finit)}</BodyText>
                                </strong>
                            ))}
                        </div>
                    </Row>

                    <SmallHeightDivider />
                    <SmallHeightDivider />
                    <TextInformation title="Requisitos" />
                    <SmallHeightDivider />

                    <TextListContainer>
                        {serviceDescription.requirements.map((requeriment, index) => (
                            <li key={index} style={{ marginTop: "5px" }}>
                                <strong>
                                    <FairBodyText>{requeriment.name}</FairBodyText>
                                </strong>
                                {requeriment.route && (
                                    <Row>
                                        <StyledDownloadIcon />
                                        <FairBodyText
                                            onClick={() => window.open(requeriment.route, "_blank")}
                                            isUrl={requeriment.route !== null}
                                        >
                                            Descargar archivo
                                        </FairBodyText>
                                    </Row>
                                )}
                                <FairBodyText>{requeriment?.description}</FairBodyText>
                            </li>
                        ))}
                    </TextListContainer>
                    <SmallHeightDivider />
                    <SmallHeightDivider />

                    <TextInformation title="Procedimientos" />
                    <SmallHeightDivider />
                    <TextOrderedListContainer>
                        {serviceDescription.procedures.map((requeriment, index) => (
                            <li key={index} style={{ marginTop: "5px" }}>
                                <BodyText>{requeriment.step}</BodyText>
                            </li>
                        ))}
                    </TextOrderedListContainer>

                    <SmallHeightDivider />
                    <SmallHeightDivider />

                    <TextInformation title="Tarifa del servicio" />
                    <SmallHeightDivider />

                    <Grid
                        alignItems="center"
                        container
                        direction="row"
                        justifyContent="center"
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                        {serviceDescription?.prices.map((price, index) => (
                            <Grid item xs={4} sm={8} md={12} key={index}>
                                <PriceContainer>
                                    <strong>
                                        <CardPriceTitle>{price.concept}</CardPriceTitle>
                                    </strong>
                                    <CardPriceTitle>{price.description}</CardPriceTitle>
                                    <SmallHeightDivider />
                                    <VariationsContainer>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <strong>
                                                <CardPriceGray>Variaciones de la tarifa</CardPriceGray>
                                            </strong>
                                            <br />
                                            {price.variations.map((variation) => (
                                                <strong>
                                                    <BodyText>{variation.concept}</BodyText>
                                                </strong>
                                            ))}
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <strong>
                                                <CardPriceGray>Tiempo de entrega</CardPriceGray>
                                            </strong>
                                            <br />
                                            {price.variations.map((variation) => (
                                                <strong>
                                                    <BodyText>{priceVariationToLaborableTime(variation.delivery_time)}</BodyText>
                                                </strong>
                                            ))}
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <strong>
                                                <CardPriceGray>Tarifa</CardPriceGray>
                                            </strong>
                                            <br />
                                            {price.variations.map((variations) => (
                                                <strong>
                                                    <BodyText>
                                                        DOP
                                                        {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                                            variations.price
                                                        )}
                                                    </BodyText>
                                                </strong>
                                            ))}
                                        </div>
                                    </VariationsContainer>
                                </PriceContainer>
                            </Grid>
                        ))}
                    </Grid>
                    <SmallHeightDivider />

                    {rolBase !== ROLE_TYPE.COMPANY && (
                        // <StyledFab onClick={() => handleServiceRequest(serviceDescription.id)}>
                        <StyledFab onClick={() => handleOpenModal(serviceID)}>
                            {
                                //prev serviceDescription?.type?.id === 1 ? "VER INFORMACION" : "INICIAR SOLICITUD"
                                // when servicedescription.type.id === 1  THE SERVICE IS ONLY INFORMATIVE AND DONT HAVE AN FORM
                                serviceDescription?.type?.id === 1 ? "INICIAR SOLICITUD" : "INICIAR SOLICITUD"
                            }
                        </StyledFab>
                    )}

                    <SmallHeightDivider />
                    <SmallHeightDivider />
                    <CommentsContainer>
                        <TextInformation title="Comentarios" />
                        <SmallHeightDivider />
                        {serviceComments.data.map((comment, index) => (
                            <Fragment key={index}>
                                <Comment
                                    key={index}
                                    userName={`${comment.citizen.name} ${comment.citizen.surname}`}
                                    comment={comment.comment}
                                    date={comment.created_at}
                                    rating={comment.rating}
                                />
                                <SmallHeightDivider />
                            </Fragment>
                        ))}

                        {serviceComments.data.length > 0 ? (
                            <PaginationContainer>
                                <StyledPagination
                                    count={serviceComments?.last_page}
                                    page={currentCommentsPage}
                                    onChange={(event, page) => {
                                        handleChangePage(page);
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    sx={{ color: COLORS.primary }}
                                />
                                <SmallHeightDivider />
                            </PaginationContainer>
                        ) : (
                            <strong>
                                <BodyText>No hay comentarios para este servicio.</BodyText>
                            </strong>
                        )}
                    </CommentsContainer>
                </Container>
            </Row>
            <MediumHeightDivider />
            <DialogSelect
                open={open}
                setOpen={setOpen}
                title="¿Cómo desea continuar?"
                setValue={setSelectedCompany}
                value={selectedCompany}
                data={companies}
                onClick={handleServiceRequest}
            />
        </Container>
    );
}

export default ServiceDescription;
