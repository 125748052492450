import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import UiReducer from "./UiReducer";
import UrlReducer from "./UrlReducer";

const RootReducer = combineReducers({
    uiReducer: UiReducer,
    authReducer: AuthReducer,
    urlReducer: UrlReducer,
});

export default RootReducer;
