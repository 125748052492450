// store.js
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Usar localStorage
import thunk from "redux-thunk";
import RootReducer from "../reducers/RootReducer";

// Configuración de redux-persist
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["urlReducer"], // Solo persistir el urlReducer
};

// Crear un reducer persistente
const persistedReducer = persistReducer(persistConfig, RootReducer);

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const Store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

// Crear el persistor
const persistor = persistStore(Store);

export { persistor, Store };
