import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getUser } from "../api/Auth";
import { AuthLogin } from "../redux/actions/AuthActions";
import { HideGlobalLoading, ShowGlobalLoading } from "../redux/actions/UiActions";
import LocalStorageService from "../services/LocalStorageService";

import { useQuery } from "react-query";

function Auth({ children }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { authenticated } = useSelector((state) => state.authReducer);

    const [isValidating, setIsValidating] = useState(true);

    useQuery(["userData"], async () => {
        const TOKEN = LocalStorageService.getItem("token");
        if (TOKEN == null) {
            // i write this 3 times because router cant be mounted before auth validation
            setIsValidating(false);
        }
        try {
            //Test current token & refreshToken (apiCall internal refresh the token in case of server return token invalid)
            dispatch(ShowGlobalLoading());
            let response = await getUser();
            if (response.success) {
                dispatch(
                    AuthLogin({
                        authenticated: true,
                        rolBase: response.payload.rol_base,
                        companyID: response.payload.company_id,
                        citizenID: response.payload.citizen_id,
                    })
                );
            } else {
                dispatch(HideGlobalLoading());
                setIsValidating(false);
                history.push("/public");
            }
            return response;
        } catch (error) {
            //LOCAL ERRORS NETWORK ETC
            //   console.log('error', error);
            //   alert('error');
        }
    });

    useEffect(() => {
        if (authenticated) {
            setIsValidating(false);
            setTimeout(() => {
                dispatch(HideGlobalLoading());
            }, 2000);
        }
    }, [authenticated, dispatch]);

    return <div>{isValidating ? null : children}</div>;
}

export default Auth;
