import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledClearIcon } from "../../views/MyDesk/subViews/Company/styles/CompanyStyles";
import { StyledButton, StyledPagination } from "../../theme/Styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleIcon from "@mui/icons-material/People";
import COLORS from "../../theme/Colors";
import { SectionTitle } from "../../views/MyDesk/styles/MyDeskStyles";

function Table({ rows, columns, onSelect, onDelete, withoutRecord, currentPage, handlePageChange }) {
  return (
    <>
      {rows.length !== 0 ? (
        <>
          <MuiTable>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell align="left" style={{ fontWeight: "bold" }} key={item.accesor}>
                    {item.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.data.map((row) => (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {columns.map((column) => (
                    <TableCell align="left" key={`${column.accesor}-${row.id}`}>
                      {column.accesor === "accion" ? (
                        <>
                          <StyledClearIcon onClick={() => onDelete(row)} />
                        </>
                      ) : column.accesor === "extra" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3px",
                          }}
                        >
                          <StyledButton
                            style={{
                              fontSize: "0.7rem",
                              padding: "4px ",
                            }}
                            startIcon={<VisibilityIcon />}
                            // onClick={() => onViewRequests(user.id)}
                          >
                            Solicitudes
                          </StyledButton>
                          {row.rol_name === "Responsable" && (
                            <StyledButton
                              style={{
                                fontSize: "0.7rem",
                                padding: "4px ",
                              }}
                              startIcon={<PeopleIcon />}
                              //  onClick={() => onViewUsers(user.id)}
                            >
                              Usuario a Cargo
                            </StyledButton>
                          )}
                        </div>
                      ) : (
                        row[column.accesor]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "2rem 0",
            }}
          >
            <StyledPagination
              count={rows?.last_page}
              page={currentPage}
              onChange={(event, page) => {
                handlePageChange(page);
              }}
              variant="outlined"
              shape="rounded"
              sx={{ color: COLORS.primary }}
            />
          </div>
        </>
      ) : (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 40, paddingBottom: 20 }}>
          <SectionTitle>{withoutRecord}</SectionTitle>
        </div>
      )}
    </>
  );
}

export default Table;
