export const requestFilterTypes = [
    {
        value: 1,
        label: "En proceso",
        variantTypes: [1, 2, 3, 4, 5, 6, 9],
    },
    {
        value: 2,
        label: "Finalizadas",
        variantTypes: [7],
    },
    // {
    //     value: 100,
    //     label: "Todas",
    //     variantTypes: [],
    // },
];

export const MockupInProcessRequests = [
    {
        id: 1,
        percent: "60%",
        title: "Solicitud de no objeción de suelo",
        status: "actionRequired",
        serviceID: 1,
    },
    {
        id: 2,
        percent: "90%",
        title: "Solicitud de prueba ABC",
        status: "inProcess",
        serviceID: 2,
    },
];

export const MockupCompletedRequests = [
    {
        id: 1,
        title: "Clasificación Provisional",
        status: "success",
        serviceID: 3,
    },
    {
        id: 2,
        title: "Guías Turísticos",
        status: "success",
        serviceID: 4,
    },
];

export const MockupRejectedRequests = [
    {
        id: 1,
        title: "Solicitud de ejemplo",
        status: "rejected",
        serviceID: 5,
    },
];

export const localDirections = [
    {
        id: 0,
        wordpressID: 130,
        wordpressKey: "todosServicios",
        title: "Todos los trámites",
    },
    {
        id: 1,
        wordpressID: 133,
        wordpressKey: "dpp",
        title: "Tramitación y Evaluación de Proyectos (DPP)",
    },
    {
        id: 2,
        wordpressID: 132,
        wordpressKey: "confotour",
        title: "Clasificaciones de Confotur",
    },
    {
        id: 3,
        wordpressID: 134,
        wordpressKey: "empresaServicios",
        title: "Licencias de Operación",
    },
];
export const listOfServicesMock = [
    {
        success: true,
        payload: {
            myCompanies: [
                {
                    id: 44,
                    company_name: "BRAULIO CXA 1",
                    company_address: "CALLE 1",
                    company_rnc: "000000001",
                    company_phone: "809-000001",
                    company_url_web: "URL del Sitio Web de la Compañía 1",
                    logo: "...",
                    applicant_name: "Hermy",
                    citizen_id: "01122222221",
                    citizen_unique_id: "00000000101122222221",
                    active: 1,
                    created_at: "2024-03-12 21:46:22",
                    updated_at: "2024-03-12 21:46:22",
                    rol_id: 1,
                    rol_name: "Admin",
                },
                {
                    id: 45,
                    company_name: "BRAULIO CXA 2",
                    company_address: "CALLE 2",
                    company_rnc: "000000002",
                    company_phone: "809-000002",
                    company_url_web: "URL del Sitio Web de la Compañía 2",
                    logo: "...",
                    applicant_name: "Hermy",
                    citizen_id: "01122222221",
                    citizen_unique_id: "00000000201122222221",
                    active: 1,
                    created_at: "2024-03-12 21:50:51",
                    updated_at: "2024-03-12 21:50:51",
                    rol_id: 1,
                    rol_name: "Admin",
                },
            ],
            otherAffiliatedCompanies: [
                {
                    id: 9,
                    company_name: "BHD LEON",
                    company_address: "AV. Winton Churchill",
                    company_rnc: "123456789",
                    company_phone: "8090000000",
                    company_url_web: "http://mi-empresa.com",
                    logo: "...",
                    applicant_name: "Cristofer",
                    citizen_id: "40226935266",
                    citizen_unique_id: "12345678940226935266",
                    active: 1,
                    created_at: "2022-01-12 23:36:39",
                    updated_at: "2022-02-10 23:46:32",
                    rol_id: 3,
                    rol_name: "Responsable",
                },
            ],
        },
    },
];

// Supongamos que tienes una lista de usuarios y sus IDs de compañía
