import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LaptopIcon from "@mui/icons-material/Laptop";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import React from "react";
import COLORS from "../../theme/Colors";
import {
    BodyText,
    ButtonsContainer,
    Container,
    RowContainer,
    ServiceButton,
    ServiceSecondaryButton,
    SpecialSubtitle,
    Subtitle,
    Title,
} from "./styles/ServiceCardStyles";

function ServiceCard({ iconName, relationTo, title, subTitle, onRequestPress, OnViewInformationPress, disableRequestButton = false }) {
    return (
        <Container>
            <div style={{ width: "95%", alignSelf: "center" }}>
                <NoteAddIcon sx={{ color: COLORS.tertiary, fontSize: "40px" }} />
                <RowContainer>
                    <Subtitle>RELACIONADO A: </Subtitle>
                    <SpecialSubtitle style={{ color: COLORS.tertiary }}>{relationTo}</SpecialSubtitle>
                </RowContainer>
                <Title>{title}</Title>
            </div>
            <div>
                <BodyText>{subTitle}</BodyText>
            </div>
            <ButtonsContainer>
                <ServiceButton disabled={disableRequestButton} onClick={onRequestPress} endIcon={<LaptopIcon />}>
                    SOLICITAR
                </ServiceButton>
                <ServiceSecondaryButton onClick={OnViewInformationPress} endIcon={<HelpOutlineIcon />}>
                    VER INFORMACIÓN
                </ServiceSecondaryButton>
            </ButtonsContainer>
        </Container>
    );
}

export default ServiceCard;
