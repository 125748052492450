import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { ROLE_TYPE } from "../../../../../constants/constants";
import COLORS from "../../../../../theme/Colors";
import { Row, SmallHeightDivider } from "../../../../../theme/Styles";
import { SectionTitle } from "../../../styles/MyDeskStyles";
import {
    BodyText,
    Column,
    ContainerCompanies,
    ContentContainer,
    LineDivider,
    RowContainer,
    StyledClearIcon,
    StyledSettingIcon,
} from "../styles/CompanyStyles";
import ModalListMemberByCompany from "./ModalListMemberByCompany";

function ListCompanyIBelongTo({ data = [], columns, title, withoutRecord, onSelect, onEdit, onDelete }) {
    const [company, setCompany] = useState(null);
    const [isModalListMembersVisible, setIsModalListMembersVisible] = useState(false);

    const handleCompanySelect = (company) => {
        setCompany(company);
        setIsModalListMembersVisible(!isModalListMembersVisible);
    };
    return (
        <>
            {data.length !== 0 ? (
                <div>
                    <SectionTitle style={{ color: COLORS.primary }}>{title}</SectionTitle>
                    <SmallHeightDivider />
                    <ContainerCompanies>
                        <SmallHeightDivider />
                        <ContentContainer>
                            <RowContainer>
                                {columns.map((column, index) => (
                                    <Column key={index} style={{ width: "20%", fontWeight: 900 }}>
                                        <BodyText>{column.label}</BodyText>
                                    </Column>
                                ))}
                            </RowContainer>
                            <SmallHeightDivider />
                            {data?.map((company, index) => (
                                <div key={index}>
                                    <RowContainer>
                                        {columns.map((column, columnIndex) => (
                                            <Column key={columnIndex} style={{ width: "20%", marginTop: 10 }}>
                                                {!column.label.includes("Seleccionar") && !column.label.includes("Editar") && (
                                                    <BodyText>{company[column.key]}</BodyText>
                                                )}
                                                {column.label === "Seleccionar" && company?.rol_id === ROLE_TYPE.REPRESENTATIVE && (
                                                    <Row
                                                        sx={{
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                        }}
                                                        onClick={() => handleCompanySelect(company)}
                                                    >
                                                        <VisibilityIcon color={"primary"} sx={{ paddingRight: "5px" }} />
                                                        <BodyText>Ver detalles</BodyText>
                                                    </Row>
                                                )}
                                                {column.label === "Editar" && company?.rol_id === ROLE_TYPE.REPRESENTATIVE && (
                                                    <StyledSettingIcon onClick={() => onEdit(company)} />
                                                )}
                                                {column.key === "Delete" && company?.rol_id === ROLE_TYPE.REPRESENTATIVE && (
                                                    <StyledClearIcon onClick={() => onDelete(company.citizen_id)} />
                                                )}
                                            </Column>
                                        ))}
                                    </RowContainer>
                                    <LineDivider />
                                </div>
                            ))}
                        </ContentContainer>
                        <SmallHeightDivider />
                        <SmallHeightDivider />
                    </ContainerCompanies>
                </div>
            ) : (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <SectionTitle>{withoutRecord}</SectionTitle>
                </div>
            )}

            {isModalListMembersVisible && (
                <ModalListMemberByCompany
                    selectedCompany={company}
                    isModalListMembersVisible={isModalListMembersVisible}
                    setIsModalListMemberVisible={setIsModalListMembersVisible}
                />
            )}
        </>
    );
}

export default ListCompanyIBelongTo;
