import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { sendMessage } from "../../../../api/ActionRequired";
import TextInformation from "../../../../components/TextInformation/TextInformation";
import { HideGlobalLoading, ShowGlobalLoading } from "../../../../redux/actions/UiActions";
import { SmallHeightDivider, StyledTextInputMessage, SubTitle } from "../../../../theme/Styles";
import { replaceGuionToSlashFromString } from "../../../../utilities/functions/StringUtil";
import {
    ButtonMessageContainer,
    ChatBottomContainer,
    ChatContainer,
    ChatTopContainer,
    Container,
    InputMessageContainer,
    MessageDateText,
    MessageItem,
    MessageText,
    StyledSendIcon,
} from "../../styles/ServiceRequestedDetailsStyles";
import { InformationFormSchema } from "./MessagesConstants";

function Messages() {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    let { requestID } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);
    const userData = queryClient.getQueryData(["userData"]);

    const messageMutation = useMutation(sendMessage, {
        onMutate: (req) => {
            dispatch(ShowGlobalLoading("Cargando"));
        },
    });

    const textFormik = useFormik({
        initialValues: {
            information: "",
        },
        validationSchema: InformationFormSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values) => {
        //now the messages actions doesnt repeat in the next line i find de data for send de required_action_id for remove from requiredActions array
        const requiredActionData = requestData?.requiredActions.find((action) => action.action_id === 3);
        const reqData = {
            request_id: requestData.request.id,
            text: values.information,
            required_action_id: requiredActionData?.id,
        };
        messageMutation.mutate(reqData, {
            onSuccess: () => {
                enqueueSnackbar("Información requerida enviada satisfactoriamente", { variant: "success" });
                queryClient.invalidateQueries(["serviceRequestedDetail", requestID]);
            },
            onError: () => {
                enqueueSnackbar("Ha ocurrido un error, contacte a soporte", { variant: "error" });
            },
            onSettled: () => {
                dispatch(HideGlobalLoading());
            },
        });
    };

    const Messages = requestData.request.comments.map((message) => {
        return {
            id: message.id,
            text: message.text,
            user_id: message?.user_id,
            isUserMessage: message?.user_id != 2 ? true : false,
            created_at: message.created_at,
        };
    });

    return (
        <Container>
            <TextInformation title="Historial de mensajes" />
            <SmallHeightDivider />
            <ChatContainer>
                <ChatTopContainer isEmpty={Messages.length > 0 ? false : true}>
                    <SmallHeightDivider />
                    {Messages.length > 0 ? (
                        Messages.map((message) => (
                            <MessageItem isUserMessage={message.isUserMessage}>
                                <MessageText>{decodeURIComponent(message.text)}</MessageText>
                                <MessageDateText>
                                    {format(new Date(replaceGuionToSlashFromString(message.created_at)), "dd 'de' MMMM yyyy hh:mm a", {
                                        locale: es,
                                    })}
                                </MessageDateText>
                            </MessageItem>
                        ))
                    ) : (
                        <SubTitle style={{ alignSelf: "center", justifySelf: "center" }}>NO HAY MENSAJES</SubTitle>
                    )}
                </ChatTopContainer>
                {requestData.request.request_actions_id == 3 && requestData.request.status.base_status_id == 1 && (
                    <ChatBottomContainer>
                        <InputMessageContainer>
                            <StyledTextInputMessage
                                multiline
                                maxRows={1}
                                placeholder="Escriba aqui su mensaje"
                                inputProps={{ maxLength: 500 }}
                                id="information"
                                value={textFormik.values.information}
                                onChange={textFormik.handleChange}
                                onBlur={textFormik.handleBlur}
                                //    error={textFormik.touched.information && Boolean(textFormik.errors.information)}
                                //    helperText={textFormik.touched.information && textFormik.errors.information}
                            />
                        </InputMessageContainer>
                        <ButtonMessageContainer>
                            <IconButton onClick={() => textFormik.handleSubmit()}>
                                <StyledSendIcon />
                            </IconButton>
                        </ButtonMessageContainer>
                    </ChatBottomContainer>
                )}
            </ChatContainer>

            {/*  <TextField id="information" title="Información"
                value={textFormik.values.information}
                onChange={textFormik.handleChange}
                onBlur={textFormik.handleBlur}
                error={textFormik.touched.information && Boolean(textFormik.errors.information)}
                helperText={textFormik.touched.information && textFormik.errors.information}
                multiline
                maxLength={255}
                required
            />
            <SmallHeightDivider />
            <ButtonContainer>
                <StyledButtonOutlined onClick={() => textFormik.handleSubmit()} variant="outlined">ENVIAR</StyledButtonOutlined>
            </ButtonContainer>
    */}
        </Container>
    );
}

export default Messages;
