
export const hourToMilliseconds = (hour) => {
  return hour * 60 * 60 *1000;
}


export const minutesToMilliseconds = (minutes) => {
  return minutes * 60000
}

