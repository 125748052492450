import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Container, Grid, IconButton, InputAdornment, MobileStepper, Step, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import CheckBox from '../../../components/CheckBox/CheckBox';
import PhoneTextField from '../../../components/PhoneTextField/PhoneTextField';
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup';
import Select from '../../../components/Select/Select';
import TextField from '../../../components/TextField/TextField';
import { HideGlobalLoading, ShowGlobalLoading } from '../../../redux/actions/UiActions';
import apiCall from '../../../services/ApiServerCall';
import wpCall from '../../../services/WpServerCall';
import {
    MediumHeightDivider,
    SmallHeightDivider,
    StyledButton,
    StyledButtonOutlined,
    StyledCheckCircleIcon,
    SubTitle,
    WpRichTextContainer
} from '../../../theme/Styles';
import { FormSchema, identificationTypes, RegisterSteps } from './RegisterConstants';
import { ButtonContainer, ButtonsContainer, FinalStepContainer } from './styles/RegisterStyles';

export default function FormRegisterPersonal() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();

    const matchesWidth = useMediaQuery('(min-width:768px)');
    const stepsTitles = RegisterSteps.map((step) => step.title);
    const stepsLenght = RegisterSteps.length;
    const [activeStep, setActiveStep] = useState(0);
    const lastStep = stepsLenght - 2 === activeStep;
    const [schemaValidation, setSchemaValidation] = useState({});
    const [userRegistered, setUserRegistered] = useState(false);

    const [provincesData, setProvincesData] = useState([]);
    const [municipalitiesData, setMunicipalitiesData] = useState([]);
    const [sectorsData, setSectorsData] = useState([]);
    const [questionsData, setQuestionsData] = useState([]);
    const [wordpressContent, setWordpressContent] = useState();
    const [isValidatingCedula, setIsValidatingCedula] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [isValidatingEmail, setIsValidatingEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

    const getProvincesData = async () => {
        try {
            let provincesData = await apiCall().get('/get/provinces');
            if (provincesData) {
                setProvincesData(
                    provincesData.data.provinces?.map((province) => ({
                        value: province.bidclasif,
                        label: province.ctituloclas
                    }))
                );
            }
            setMunicipalitiesData([]);
            setSectorsData([]);
        } catch (error) {}
    };

    const getRegisterQuestionsData = async () => {
        try {
            let questionsData = await apiCall().get('/auth/get/getquestionandanswer');
            if (questionsData) {
                setQuestionsData(
                    questionsData.data.payload?.map((question) => ({
                        value: question.id,
                        label: question.questions
                    }))
                );
            }
        } catch (error) {}
    };

    const getAndSetAllWordPressContent = async () => {
        let data = await wpCall().get('/pages/v1/page/terminos-y-condiciones');
        setWordpressContent(data?.data?.content);
    };

    useEffect(() => {
        getProvincesData();
        getRegisterQuestionsData();
        getAndSetAllWordPressContent();
    }, []);

    useEffect(() => {
        const innerSchema = FormSchema({
            setIsValidatingCedula,
            isValidatingEmail,
            setIsValidatingEmail,
            isValidated,
            setIsValidated
        })[activeStep];
        if (typeof innerSchema === 'object') {
            setSchemaValidation(innerSchema.validations);
        }
        return () => {};
    }, [activeStep, isValidated, isValidatingEmail]);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowRepeatedPassword = () => setShowRepeatedPassword(!showRepeatedPassword);

    const formik = useFormik({
        initialValues: {
            email: '',
            emailConfirmation: '',
            password: '',
            password_confirmation: '',
            document_type: '1',
            citizen_id: '',
            name: '',
            first_last_name: '',
            second_last_name: '',
            occupation: '',
            province_id: '',
            municipality_id: '',
            sector_id: '',
            phone: '',
            phone2: '',
            email2: '',
            address: '',
            first_citizen_question: '',
            first_citizen_answer: '',
            second_citizen_question: '',
            second_citizen_answer: '',
            third_citizen_question: '',
            third_citizen_answer: '',
            termsAndCondition: false
        },
        validationSchema: schemaValidation,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values, actions) => {
            validateStepForm({ values, actions });
        }
    });

    const handleStepsValidation = (step) => {
        if (RegisterSteps[step]) {
            let stepElementError = false;
            for (let i = 0; i < RegisterSteps[step].elements?.length; i++) {
                const element = RegisterSteps[step].elements[i];
                if (formik.touched[element] && Boolean(formik.errors[element])) {
                    stepElementError = true;
                }
            }
            if (stepElementError) {
                return true;
            } else {
                return false;
            }
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1 === stepsLenght ? prevActiveStep : prevActiveStep + 1));
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 1 : prevActiveStep - 1));
    };

    const handleFieldsValidations = (errors) => {
        let fieldsKeys = Object.keys(errors);
        for (let i = 0; i < fieldsKeys.length; i++) {
            let error = errors[fieldsKeys[i]];
            formik.setFieldError(fieldsKeys[i], error[0]);
        }
    };

    const validateStepForm = ({ values, actions }) => {
        if (lastStep) {
            handleRegister(values);
        } else {
            setActiveStep(activeStep + 1);
            actions?.setTouched({});
            actions?.setSubmitting(false);
        }
    };

    const handleSubmitForm = (e) => {
        let nextStepErrorHasAnError;
        Object.keys(formik.errors).forEach((item) => {
            if (RegisterSteps[activeStep + 1]?.elements.includes(item)) {
                nextStepErrorHasAnError = true;
            }
        });
        window.scrollTo(0, 0);
        if (Object.keys(formik.errors).length !== 0 && !nextStepErrorHasAnError) {
            enqueueSnackbar('Llene todos los campos requeridos', { variant: 'error' });
            return;
        } else {
            formik.handleSubmit(e);
        }
    };

    const getMunicipalitiesData = async (value) => {
        try {
            let municipalitiesData = await apiCall().get(`/get/municipalities/${value}`);
            if (municipalitiesData) {
                setMunicipalitiesData(
                    municipalitiesData.data.municipalities?.map((municipalities) => ({
                        value: municipalities.bidclasif,
                        label: municipalities.ctituloclas
                    }))
                );
            }
            setSectorsData([]);
        } catch (error) {}
    };

    const getSectorsData = async (value) => {
        try {
            let sectorsData = await apiCall().get(`/get/sectors/${value}`);
            if (sectorsData) {
                setSectorsData(
                    sectorsData.data.sectors?.map((sector) => ({
                        value: sector.bidclasif,
                        label: sector.ctituloclas
                    }))
                );
            }
        } catch (error) {}
    };

    const questionsForShow = (questionNumber) => {
        //questionNumber == 0,1,2
        let selectedOptions = [formik.values.first_citizen_question, formik.values.second_citizen_question, formik.values.third_citizen_question];
        let optionsForRemove = selectedOptions.filter((option, index) => index !== questionNumber);
        return questionsData.filter((question) => !optionsForRemove.includes(question.value + ''));
    };

    const handleSecurityQuestionChange = (e) => {
        formik.setFieldValue(e.target.id, e.target.value);
    };

    const handleRegister = async (formData) => {
        dispatch(ShowGlobalLoading('Cargando'));
        try {
            let response = await apiCall().post('/auth/register/portal', {
                document_type: formData.document_type,
                citizen_id: formData.citizen_id,
                email: formData.email,
                name: formData.name,
                first_last_name: formData.first_last_name,
                second_last_name: formData.second_last_name,
                occupation: formData.occupation,
                password: formData.password,
                password_confirmation: formData.password_confirmation,
                province_id: formData.province_id,
                municipality_id: formData.municipality_id,
                sector_id: formData.sector_id,
                phone: formData.phone,
                phone2: formData.phone2,
                email2: formData.email2,
                address: formData.address
            });
            if (response.data?.success) {
                await apiCall().post('/auth/register/questionandanswer', [
                    {
                        citizen_question: formData.first_citizen_question,
                        citizen_answer: formData.first_citizen_answer,
                        citizen_id: formData.citizen_id
                    },
                    {
                        citizen_question: formData.second_citizen_question,
                        citizen_answer: formData.second_citizen_answer,
                        citizen_id: formData.citizen_id
                    },
                    {
                        citizen_question: formData.third_citizen_question,
                        citizen_answer: formData.third_citizen_answer,
                        citizen_id: formData.citizen_id
                    }
                ]);
                setUserRegistered(true);
                handleNext();
            } else {
                //handle errors from server
                if (typeof response.data?.msg?.error === 'object') {
                    handleFieldsValidations(response.data?.msg?.error);
                    enqueueSnackbar('Hay uno o mas errores con la información introducida. Valídalos e inténtalo nuevamente', {
                        variant: 'error'
                    });
                    window.scrollTo(0, 0);
                } else {
                    enqueueSnackbar('Ha sucedido un error inténtelo mas tarde o contacte a soporte', {
                        variant: 'error'
                    });
                    window.scrollTo(0, 0);
                }
            }
        } catch (error) {
            //handle local/network errors
        }
        dispatch(HideGlobalLoading());
    };

    // Lógica para validar solo al perder el foco (onBlur)
    const handleBlur = async (event) => {
        const field = event.target.id;
        await formik.validateField(field);
        formik.setFieldTouched(field, true); // Marca el campo como tocado
    };

    return (
        <Container>
            {matchesWidth && (
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {stepsTitles.map((label, index) => {
                        const labelProps = {};
                        if (handleStepsValidation(index)) {
                            labelProps.optional = (
                                <Typography
                                    sx={{ marginLeft: '45%' }}
                                    variant="caption"
                                    color="error"
                                >
                                    Error
                                </Typography>
                            );
                            labelProps.error = true;
                        }
                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            )}
            <MediumHeightDivider />
            {activeStep === 0 ? (
                <Grid
                    alignItems="center"
                    justifyContent="center"
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 8, sm: 8, md: 12 }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Correo Electrónico"
                            type="email"
                            id="email"
                            required
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Confirmar correo electrónico"
                            type="email"
                            id="emailConfirmation"
                            required
                            value={formik.values.emailConfirmation}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.emailConfirmation && Boolean(formik.errors.emailConfirmation)}
                            helperText={formik.touched.emailConfirmation && formik.errors.emailConfirmation}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                                </InputAdornment>
                            }
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Confirmar contraseña"
                            type={showRepeatedPassword ? 'text' : 'password'}
                            id="password_confirmation"
                            value={formik.values.password_confirmation}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowRepeatedPassword}>
                                        {showRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            required
                        />
                    </Grid>
                </Grid>
            ) : activeStep === 1 ? (
                <Grid
                    alignItems="center"
                    justifyContent="flex-start"
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 8, sm: 8, md: 12 }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <RadioButtonGroup
                            title="Tipo de documento"
                            id="document_type"
                            options={identificationTypes}
                            value={formik.values.document_type}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.document_type && Boolean(formik.errors.document_type)}
                            helperText={formik.touched.document_type && formik.errors.document_type}
                            required
                            row
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <TextField
                            title="Documento de Identidad"
                            type="text"
                            id="citizen_id"
                            required
                            isLoading={isValidatingCedula}
                            mask={formik.values.document_type === '1' ? '999-9999999-9' : ''}
                            unMaskedValue={true}
                            value={formik.values.citizen_id}
                            onChange={(e) => {
                                setIsValidated(false);
                                formik.handleChange(e);
                            }}
                            onBlur={handleBlur}
                            error={formik.touched.citizen_id && Boolean(formik.errors.citizen_id)}
                            helperText={formik.touched.citizen_id && formik.errors.citizen_id}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <TextField
                            title="Nombre"
                            type="text"
                            id="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <TextField
                            title="Primer apellido"
                            type="text"
                            id="first_last_name"
                            value={formik.values.first_last_name}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.first_last_name && Boolean(formik.errors.first_last_name)}
                            helperText={formik.touched.first_last_name && formik.errors.first_last_name}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <TextField
                            title="Segundo Apellido"
                            type="text"
                            id="second_last_name"
                            value={formik.values.second_last_name}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.second_last_name && Boolean(formik.errors.second_last_name)}
                            helperText={formik.touched.second_last_name && formik.errors.second_last_name}
                            //required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <Select
                            title="Provincia"
                            type="text"
                            id="province_id"
                            data={provincesData}
                            value={formik.values.province_id}
                            onChange={(e) => {
                                formik.setFieldValue('municipality_id', '');
                                formik.setFieldValue('sector_id', '');
                                formik.handleChange(e);
                                getMunicipalitiesData(e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={formik.touched.province_id && Boolean(formik.errors.province_id)}
                            helperText={formik.touched.province_id && formik.errors.province_id}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <Select
                            title="Municipio"
                            type="text"
                            id="municipality_id"
                            disabled={!municipalitiesData.length > 0}
                            data={municipalitiesData}
                            value={formik.values.municipality_id}
                            onChange={(e) => {
                                formik.setFieldValue('sector_id', '');
                                formik.handleChange(e);
                                getSectorsData(e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={formik.touched.municipality_id && Boolean(formik.errors.municipality_id)}
                            helperText={formik.touched.municipality_id && formik.errors.municipality_id}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <Select
                            title="Sector"
                            type="text"
                            id="sector_id"
                            disabled={!sectorsData.length > 0}
                            data={sectorsData}
                            value={formik.values.sector_id}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.sector_id && Boolean(formik.errors.sector_id)}
                            helperText={formik.touched.sector_id && formik.errors.sector_id}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={8}
                        md={6}
                    >
                        <TextField
                            title="Dirección"
                            type="text"
                            id="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <PhoneTextField
                            title="Teléfono de contacto"
                            type="text"
                            id="phone"
                            required
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        sm={4}
                        md={6}
                    >
                        <PhoneTextField
                            title="Teléfono de contacto secundario"
                            type="text"
                            id="phone2"
                            value={formik.values.phone2}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.phone2 && Boolean(formik.errors.phone2)}
                            helperText={formik.touched.phone2 && formik.errors.phone2}
                        />
                    </Grid>
                </Grid>
            ) : activeStep === 2 ? (
                <Grid
                    alignItems="center"
                    justifyContent="center"
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 8, sm: 8, md: 12 }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <Select
                            title="Pregunta de seguridad primaria"
                            id="first_citizen_question"
                            data={questionsForShow(0)}
                            value={formik.values.first_citizen_question}
                            onChange={handleSecurityQuestionChange}
                            onBlur={handleBlur}
                            error={formik.touched.first_citizen_question && Boolean(formik.errors.first_citizen_question)}
                            helperText={formik.touched.first_citizen_question && formik.errors.first_citizen_question}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Respuesta de seguridad primaria"
                            type="text"
                            id="first_citizen_answer"
                            required
                            value={formik.values.first_citizen_answer}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.first_citizen_answer && Boolean(formik.errors.first_citizen_answer)}
                            helperText={formik.touched.first_citizen_answer && formik.errors.first_citizen_answer}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <Select
                            title="Pregunta de seguridad secundaria"
                            id="second_citizen_question"
                            data={questionsForShow(1)}
                            value={formik.values.second_citizen_question}
                            onChange={handleSecurityQuestionChange}
                            onBlur={handleBlur}
                            error={formik.touched.second_citizen_question && Boolean(formik.errors.second_citizen_question)}
                            helperText={formik.touched.second_citizen_question && formik.errors.second_citizen_question}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Respuesta de seguridad secundaria"
                            type="text"
                            id="second_citizen_answer"
                            required
                            value={formik.values.second_citizen_answer}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.second_citizen_answer && Boolean(formik.errors.second_citizen_answer)}
                            helperText={formik.touched.second_citizen_answer && formik.errors.second_citizen_answer}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <Select
                            title="Pregunta de seguridad terciaria"
                            id="third_citizen_question"
                            data={questionsForShow(2)}
                            value={formik.values.third_citizen_question}
                            onChange={handleSecurityQuestionChange}
                            onBlur={handleBlur}
                            error={formik.touched.third_citizen_question && Boolean(formik.errors.third_citizen_question)}
                            helperText={formik.touched.third_citizen_question && formik.errors.third_citizen_question}
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <TextField
                            title="Respuesta de seguridad terciaria"
                            type="text"
                            id="third_citizen_answer"
                            required
                            value={formik.values.third_citizen_answer}
                            onChange={formik.handleChange}
                            onBlur={handleBlur}
                            error={formik.touched.third_citizen_answer && Boolean(formik.errors.third_citizen_answer)}
                            helperText={formik.touched.third_citizen_answer && formik.errors.third_citizen_answer}
                        />
                    </Grid>
                </Grid>
            ) : activeStep === 3 ? (
                <Fragment>
                    {/*<Title>TERMINOS Y CONDICIONES</Title>*/}

                    <WpRichTextContainer>{wordpressContent && parse(wordpressContent)}</WpRichTextContainer>

                    <SmallHeightDivider />
                    <CheckBox
                        id="termsAndCondition"
                        onChange={formik.handleChange}
                        onBlur={handleBlur}
                        error={formik.touched.termsAndCondition && Boolean(formik.errors.termsAndCondition)}
                        helperText={formik.touched.termsAndCondition && formik.errors.termsAndCondition}
                        value={formik.values.termsAndCondition}
                        label="Acepto los terminos y condiciones de uso y privacidad"
                    />
                </Fragment>
            ) : (
                <FinalStepContainer>
                    <StyledCheckCircleIcon />
                    <SmallHeightDivider />
                    <SubTitle>¡Gracias! Recibirás un correo de verificación.</SubTitle>
                </FinalStepContainer>
            )}

            <MediumHeightDivider />
            <MediumHeightDivider />

            <ButtonsContainer>
                <ButtonContainer>
                    <StyledButtonOutlined
                        disabled={activeStep === 0 || userRegistered}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Retroceder
                    </StyledButtonOutlined>
                </ButtonContainer>

                {
                    //STEPPER WHEN DEVICE IS MOBILE
                    !matchesWidth && (
                        <MobileStepper
                            variant="dots"
                            steps={stepsLenght}
                            position="static"
                            activeStep={activeStep}
                        />
                    )
                }

                <ButtonContainer>
                    {userRegistered ? (
                        <StyledButton onClick={() => history.push('/public/login')}>Ingresar</StyledButton>
                    ) : (
                        <StyledButtonOutlined
                            onClick={handleSubmitForm}
                            variant="outlined"
                        >
                            {lastStep ? 'Registrar' : 'Continuar'}
                        </StyledButtonOutlined>
                    )}
                </ButtonContainer>
            </ButtonsContainer>
        </Container>
    );
}
