import * as yup from "yup";
import SelectIcon from '../../../../assets/icons/select-12.svg'


export const SelectIconCompany = SelectIcon;
const urlForYup = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const FormCompanySchema = yup.object({
  company_name: yup.string().required('Este campo es requerido'),
  company_rnc: yup.string().required('Este campo es requerido'),
  company_phone: yup.string().required('Este campo es requerido'),
  // email: yup.string().email('Ingrese un email valido').required('Este campo es requerido'),
  company_address: yup.string().required('Este campo es requerido'),
  company_url_web: yup.string().matches(urlForYup, "Dirección web inválida"),
}).required();

export const FormCompanyMenberSchema = yup.object({
  company: yup.string().required('Este campo es requerido'),
  email: yup.string().email('Ingrese un email valido').required('Este campo es requerido'),
}).required();

export const FormCompanySendInviteSchema = yup.object({
  citizen_id: yup.string().required('Este campo es requerido').min(9, 'Digite un número de documento de identidad válido'),
}).required();



export const MockupCompanies = [
  {
    id: 1,
    title: 'Restaurant & Bar Grill',
    identification: '001-6585665-5',
    phoneNumber: '809-123-4567',
    city: 'Santo Domingo',
    companyName: 'Restaurant & Bar Grill',
    email: 'Robert@googlemail.com',
    address: 'Av. Winston Churchill #54, Piantini',
    category: 'Turismo / Restaurante'
  },

  {
    id: 2,
    title: 'Concentra Hotels & Resorts',
    identification: '001-2224445-5',
    phoneNumber: '809-222-1100',
    city: 'Santo Domingo',
    companyName: 'Concentra Hotels & Resorts',
    email: 'concentra@concentra.com',
    address: 'Av. Winston Churchill #54, Piantini',
    category: 'Turismo / Resorts'
  },
]

export const customColumnCompany = [
  { label: 'RNC', key: 'company_rnc' },
  { label: 'Nombre', key: 'company_name' },
  { label: 'Teléfono', key: 'company_phone' },
  { label: 'Cargo', key: 'rol_name' },
  { label: 'Seleccionar', key: 'Select' },
  // { label: 'Eliminar', key: 'Delete' }
];

export const customColumnCompanyListRepresentatives = [
  { label: 'Cédula / Pasaporte', key: 'citizen_id' },
  { label: 'Nombre', key: 'name' },
  { label: 'Teléfono', key: 'phone' },
  { label: 'Correo', key: 'email' },
  { label: 'Eliminar', key: 'Delete' }
];



export const customColumnOtherCompany = [
  { label: 'Cargo', key: 'rol_name' },
  { label: 'RNC', key: 'company_rnc' },
  { label: 'Nombre', key: 'company_name' },
  { label: 'Teléfono', key: 'company_phone' },
  { label: 'Seleccionar' },
  /*   { label: 'Editar' } */
];

export const columns = [
  { accesor: 'name', title: 'Nombre' },
  { accesor: 'first_last_name', title: 'Apellido' },
  { accesor: 'citizen', title: 'Cédula/Pasaporte' },
  { accesor: 'phone', title: 'Teléfono' },
  { accesor: 'occupation', title: 'Ocupación' },
  { accesor: 'accion', title: 'Eliminar' },
];

export const columnsCompanyApplicant = [
  { accesor: 'name', title: 'Nombre' },
  { accesor: 'first_last_name', title: 'Apellido' },
  { accesor: 'citizen', title: 'Cédula/Pasaporte' },
  { accesor: 'phone', title: 'Teléfono' },
  { accesor: 'occupation', title: 'Ocupación' },
];

export const usersMembers = [
  {
    id: 1,
    name: 'John Doe',
    cedula: '123456789',
    company_id: 9,
    company_phone: '8090000000',
    rol_name: 'Responsable'
  },
  {
    id: 2,
    name: 'Jane Smith',
    cedula: '987654321',
    company_id: 9,
    company_phone: '8091111111',
    rol_name: 'Responsable'
  },
  // Asegúrate de que cada usuario tenga un ID único
  {
    id: 3,
    name: 'Alice Johnson',
    cedula: '456789123',
    company_id: 9,
    company_phone: '8092222222',
    rol_name: 'Responsable'
  },
  // Más usuarios con ID únicos...
];


export const MenuOptions = {
  companySelected: 1,
  inviteUser: 2,
}