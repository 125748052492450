import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { UpdateAppSubHeaderTitle } from '../../redux/actions/UiActions';
import {
    ButtonContainer,
    ButtonsContainer,
    CenterContainer,
    Container,
} from './styles/AcceptMeberShipStyles';
import GlobalLoading from '../../components/GlobalLoading/GlobalLoading';
import {
    MediumHeightDivider,
    SmallHeightDivider,
    StyledButton,
    StyledCancelIcon,
    StyledCheckCircleIcon,
    SubTitle,
} from '../../theme/Styles';
import { acceptMembership } from '../../api/myCompany';
import { useSnackbar } from 'notistack';
const AcceptMembership = () => {
    const [InvitedSuccess, setInvitedSuccess] = useState();
    const [responseMsg, setResponseMsg] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const { authenticated } = useSelector((state) => state.authReducer);

    const handleAcceptMembership = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        const data = {
            citizenId: urlParams.get('citizen_id'),
            companyId: urlParams.get('company_id'),
            tokenMembership: urlParams.get('token_code'),
        };
        try {
            let response = await acceptMembership(data);
            setResponseMsg(response)
            if (response.success) {
                setInvitedSuccess(true);
                setIsLoading(false);
            } else {
                setInvitedSuccess(false);
                setIsLoading(false);
            }
        } catch (error) {
            setInvitedSuccess(false);
            enqueueSnackbar('Ha ocurrido un error, contacte a soporte', {
                variant: 'error',
            });
            setIsLoading(false);
        }
    };
    useLayoutEffect(() => {

        dispatch(UpdateAppSubHeaderTitle('Verificación de invitacion')); // TITLE OF SUBHEADER APP
        handleAcceptMembership();
    }, []);

    const handleButtonClick = () => {
        if (authenticated) {
            history.push('/app/myDesk');
        } else {

            history.push('/public/login');
        }
    };
    return (
        <Container>
            <GlobalLoading
                showByProp={isLoading}
                textByProp={'Aceptando Invitacion'}
            />
            <SmallHeightDivider />
            {InvitedSuccess === undefined ? null : (
                <CenterContainer>
                    {InvitedSuccess ? <StyledCheckCircleIcon /> : <StyledCancelIcon />}
                    <SmallHeightDivider />
                    <SubTitle>
                        {responseMsg.msg}
                    </SubTitle>
                    <MediumHeightDivider />
                    <MediumHeightDivider />
                    <ButtonsContainer sx={{ justifyContent: ' center' }}>
                        <ButtonContainer>
                            <StyledButton onClick={handleButtonClick}>
                                {authenticated ? 'Ir a mi escritorio' : 'Ir a Login'}
                            </StyledButton>
                        </ButtonContainer>

                    </ButtonsContainer>
                </CenterContainer>
            )}
            <MediumHeightDivider />
            <MediumHeightDivider />
        </Container>
    );
};

export default AcceptMembership;
