import ButtonGroup from "@mui/material/ButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useLayoutEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getMetricsData } from "../../api/myDesk";
import CenterLoading from "../../components/CenterLoading/CenterLoading";
import ServiceDirectoryMenu from "../../components/ServiceDirectoryMenu/ServiceDirectoryMenu";
import { ROLE_TYPE } from "../../constants/constants";
import { UpdateAppSubHeaderTitle } from "../../redux/actions/UiActions";
import {
    ButtonsMenuContainer,
    CardContainer,
    MediumHeightDivider,
    Row,
    RowBodyDivider,
    SmallHeightDivider,
    StyledButtonOutlined,
} from "../../theme/Styles";
import { Container, MetricsContentDivider, MetricsTextContainer, MetricsTitle, MetricsValue } from "./styles/MyDeskStyles";
import Companies from "./subViews/Company/Company";
import MyDocuments from "./subViews/myDocuments/MyDocuments";
import MyInstitutionalDocuments from "./subViews/myInstitutionalDocuments/MyInstitutionalDocuments";
import MyProfile from "./subViews/myProfile/MyProfile";
import MyRequests from "./subViews/MyRequests/MyRequests";
import MyRequestsCompany from "./subViews/MyRequests/MyRequestsCompany";

function MyDesk() {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { rolBase } = useSelector((state) => state.authReducer);

    const [ActiveMenu, setActiveMenu] = useState(1); //0 MI PERFIL , 1 MIS SOLICITUDES, 2 MIS DOCUMENTOS
    const [ActiveDocumentMenu, setActiveDocumentMenu] = useState(0); //0 DOCUMENTOS PERSONALES ,1 DOCUMENTOS INSTITUCIONALES

    const userData = queryClient.getQueryData(["userData"]);
    const { data: metricsData, isLoading: metricsDataIsLoading } = useQuery(
        ["myDeskMetricsData"],
        () => getMetricsData(userData.payload.citizen_id),
        {
            enabled: userData?.payload?.citizen_id !== undefined,
            cacheTime: 0, // Deshabilita la caché
            staleTime: 0, // Opcional: asegura que los datos siempre se marquen como obsoletos
        }
    );

    const handleChangeMenu = (menuID) => {
        setActiveMenu(menuID);
    };

    const handleChangeDocumentMenu = (menuID) => {
        setActiveDocumentMenu(menuID);
    };

    useLayoutEffect(() => {
        dispatch(UpdateAppSubHeaderTitle("Mi escritorio")); //SET SUBHEADER TITLE
    }, [dispatch]);

    if (metricsDataIsLoading) return <CenterLoading />;

    return (
        <Container>
            <Row>
                {matchesWidth && (
                    <Fragment>
                        <ServiceDirectoryMenu />
                        <RowBodyDivider />
                    </Fragment>
                )}
                <Container>
                    <SmallHeightDivider />
                    <CardContainer>
                        <MetricsTextContainer>
                            <MetricsTitle>Solicitudes en proceso</MetricsTitle>
                            <MetricsValue>{metricsData?.reqsOpen}</MetricsValue>
                        </MetricsTextContainer>
                        <MetricsContentDivider />
                        <MetricsTextContainer>
                            <MetricsTitle>Solicitudes completadas</MetricsTitle>
                            <MetricsValue>{metricsData?.reqsComplete}</MetricsValue>
                        </MetricsTextContainer>
                        <MetricsContentDivider />
                        <MetricsTextContainer>
                            <MetricsTitle>Documentos subidos</MetricsTitle>
                            <MetricsValue>{metricsData?.documents}</MetricsValue>
                        </MetricsTextContainer>
                        <MetricsContentDivider />
                        <MetricsTextContainer>
                            <MetricsTitle>Solicitudes rechazadas</MetricsTitle>
                            <MetricsValue>{metricsData?.reqsRejected}</MetricsValue>
                        </MetricsTextContainer>
                    </CardContainer>

                    <MediumHeightDivider />
                    <ButtonsMenuContainer>
                        <ButtonGroup size="large">
                            <StyledButtonOutlined active={ActiveMenu === 0} onClick={() => handleChangeMenu(0)}>
                                Mi Perfil
                            </StyledButtonOutlined>

                            <StyledButtonOutlined active={ActiveMenu === 3} onClick={() => handleChangeMenu(3)}>
                                Empresas
                            </StyledButtonOutlined>

                            <StyledButtonOutlined active={ActiveMenu === 1} onClick={() => handleChangeMenu(1)}>
                                {rolBase !== ROLE_TYPE.COMPANY ? "Mis solicitudes" : "Solicitudes"}
                            </StyledButtonOutlined>
                            <StyledButtonOutlined active={ActiveMenu === 2} onClick={() => handleChangeMenu(2)}>
                                Documentos
                            </StyledButtonOutlined>
                        </ButtonGroup>
                    </ButtonsMenuContainer>

                    {ActiveMenu === 0 ? ( // MI PERFIL
                        <MyProfile rolBase={rolBase} />
                    ) : ActiveMenu === 1 ? ( // SOLICITUDES EN PROCESO
                        rolBase === ROLE_TYPE.COMPANY ? (
                            <MyRequestsCompany rolBase={rolBase} />
                        ) : (
                            <MyRequests rolBase={rolBase} />
                        )
                    ) : ActiveMenu === 3 ? ( // SOLICITUDES EN PROCESO
                        <Companies rolBase={rolBase} />
                    ) : (
                        // DOCUMENTOS
                        <Fragment>
                            <MediumHeightDivider />
                            <ButtonsMenuContainer>
                                <ButtonGroup size="large">
                                    <StyledButtonOutlined active={ActiveDocumentMenu === 0} onClick={() => handleChangeDocumentMenu(0)}>
                                        Documentos Personales
                                    </StyledButtonOutlined>
                                    <StyledButtonOutlined active={ActiveDocumentMenu === 1} onClick={() => handleChangeDocumentMenu(1)}>
                                        Documentos Institucionales
                                    </StyledButtonOutlined>
                                </ButtonGroup>
                            </ButtonsMenuContainer>

                            {ActiveDocumentMenu === 0 ? <MyDocuments /> : <MyInstitutionalDocuments />}
                        </Fragment>
                    )}
                </Container>
            </Row>
        </Container>
    );
}

export default MyDesk;
