import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { deleteRequest } from "../../../../api/ServiceRequestedDetails";
import DocumentsOfRequestsCard from "../../../../components/DocumentsOfRequestsCard/DocumentsOfRequestsCard";
import { SweetAlert } from "../../../../components/SweetAlert/SweetAlert";
import TextInformation from "../../../../components/TextInformation/TextInformation";
import { HideGlobalLoading, ShowGlobalLoading } from "../../../../redux/actions/UiActions";
import { BodyText, BodyTextBold, SmallHeightDivider, StyledButtonOutlined } from "../../../../theme/Styles";
import {
    filterDetailsData,
    filterDocuments,
    filterDocumentsInstitution,
} from "../../../../utilities/functions/ServiceRequestedDetailsUtil";
import { replaceGuionToSlashFromString } from "../../../../utilities/functions/StringUtil";
import { ButtonHeaderContainer, Container } from "../../styles/ServiceRequestedDetailsStyles";

function Details() {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    let { requestID, docIdentification, companyID, companyName, userRolCompany, statusID } = useParams();
    const dispatch = useDispatch();
    const { rolBase, citizenID } = useSelector((state) => state.authReducer);
    const queryClient = useQueryClient();

    const documentsRef = useRef(null);

    const requestData = queryClient.getQueryData(["serviceRequestedDetail", requestID]);

    const userData = queryClient.getQueryData(["userData"]);

    const mutation = useMutation(deleteRequest);

    const documentsData = useMemo(() => filterDocuments(requestData?.request?.request_document), [requestData?.request?.request_document]);
    const documentsInstitutionData = useMemo(
        () => filterDocumentsInstitution(requestData?.request?.request_document),
        [requestData?.request?.request_document]
    );
    const detailData = useMemo(() => filterDetailsData(requestData?.data), [requestData?.data]);

    const handleChangeReportStatus = () => {
        SweetAlert.fire({
            icon: "warning",
            title: "Esta seguro de eliminar esta solicitud?",
            text: "Una vez realizada esta acción no podra revertirse",
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteRequest();
            }
        });
    };

    const handleDeleteRequest = () => {
        dispatch(ShowGlobalLoading("Cargando"));
        mutation.mutate(
            {
                citizen_id: userData?.payload?.citizen_id,
                request_id: requestID,
            },
            {
                onSuccess: () => {
                    queryClient.removeQueries(["serviceRequestedDetail", requestID], { exact: true });
                    queryClient.invalidateQueries(["requestsList"]);
                    history.push(`/app/myDesk`);
                },
                onSettled: () => {
                    dispatch(HideGlobalLoading());
                },
            }
        );
    };

    useEffect(() => {
        if (window.location.hash === "#documents") {
            //When documents action required its send scroll to documents and remove hash
            documentsRef?.current?.scrollIntoView();
        }
    }, [window.location.hash]);

    return (
        <Container>
            <TextInformation
                title="Detalles de Solicitud"
                enableRightContent={requestData?.request?.provisional === 1}
                rightContent={
                    docIdentification == citizenID && statusID != 10 ? (
                        <ButtonHeaderContainer>
                            <StyledButtonOutlined variant="outlined" onClick={handleChangeReportStatus}>
                                Eliminar Solicitud
                            </StyledButtonOutlined>
                        </ButtonHeaderContainer>
                    ) : null
                }
            />
            <SmallHeightDivider />
            <SmallHeightDivider />
            <Grid
                alignItems="center"
                justifyContent="flex-start"
                container
                direction="row"
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 6, sm: 8, md: 12 }}
            >
                {requestData?.request?.provisional === 0 && (
                    <Grid item xs={6} sm={4} md={4}>
                        <BodyTextBold>Numero de solicitud:</BodyTextBold>
                        <BodyText>{requestData?.request?.code}</BodyText>
                    </Grid>
                )}

                <Grid item xs={6} sm={4} md={4}>
                    <BodyTextBold>Estatus:</BodyTextBold>
                    <BodyText>{requestData?.request?.status?.name}</BodyText>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                    <BodyTextBold>Fecha:</BodyTextBold>
                    <BodyText>{new Date(replaceGuionToSlashFromString(requestData?.request?.created_at)).toLocaleDateString()}</BodyText>
                </Grid>

                {detailData.map((item) => (
                    <Grid item xs={6} sm={4} md={4}>
                        <BodyTextBold>{item.label}</BodyTextBold>
                        <BodyText>{item.value}</BodyText>
                    </Grid>
                ))}
            </Grid>

            <SmallHeightDivider />
            <SmallHeightDivider />
            {documentsInstitutionData?.length > 0 && (
                <div>
                    <TextInformation title="Documentos emitidos por institución" />
                    <SmallHeightDivider />
                    <DocumentsOfRequestsCard data={documentsInstitutionData} />
                </div>
            )}

            <SmallHeightDivider />
            <SmallHeightDivider />
            {documentsData?.length > 0 && (
                <div ref={documentsRef} name="documents">
                    <TextInformation title="Documentos subidos" />
                    <SmallHeightDivider />
                    <DocumentsOfRequestsCard data={documentsData} />
                </div>
            )}
        </Container>
    );
}

export default Details;
