import { MediumHeightDivider, SmallHeightDivider, StyledButtonOutlined } from "../../../../../theme/Styles";
import FormModal from "../../../../../components/FormModal/FormModal";
import { ButtonContainer } from "../styles/CompanyStyles";
import Select from "../../../../../components/Select/Select";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAllMembersByCompanyID } from "../../../../../api/myCompany";

function DialogChangeApplicant({
    claimModalVisible,
    handleClaimModalVisibility,
    searchUser,
    deleteFoundUser,
    companySelected,
    onClick,
    title,
    sendButtonLabel,
}) {
    const initialData = {
        value: "",
        label: "Selecciona un miembro",
    };
    const [requestMembers, setRequestMembers] = useState([]);
    const [requestMemberId, setRequestMemberId] = useState("");

    const { data: usersMembers } = useQuery(["allMembers", companySelected], () => getAllMembersByCompanyID(companySelected));

    const handleChangeType = (value) => {
        setRequestMemberId(value.target.value);
    };

    useEffect(() => {
        if (usersMembers?.payload?.data) {
            const reducedArray = usersMembers.payload.data.reduce((acc, curr) => {
                if (!acc.some((item) => item.value === curr.citizen)) {
                    acc.push({ value: curr.citizen, label: `${curr.name} ${curr.first_last_name}` });
                }
                return acc;
            }, []);
            setRequestMembers(() => [initialData, ...reducedArray]);
        }
    }, [usersMembers]);

    return (
        <FormModal
            conditionalClose={true}
            open={claimModalVisible}
            onClose={() => {
                setRequestMemberId("");
                handleClaimModalVisibility();
            }}
            title={title}
        >
            <SmallHeightDivider />

            <Select id="selectMembers" data={requestMembers} value={requestMemberId} onChange={handleChangeType} disableEmptyValue />

            <MediumHeightDivider />
            <MediumHeightDivider />
            <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <ButtonContainer>
                    <StyledButtonOutlined
                        disabled={requestMemberId === 0}
                        onClick={() => {
                            onClick(requestMemberId);
                            setRequestMemberId("");
                        }}
                        variant="outlined"
                    >
                        {sendButtonLabel}
                    </StyledButtonOutlined>
                </ButtonContainer>
            </div>
        </FormModal>
    );
}

export default DialogChangeApplicant;
