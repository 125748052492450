import FormControl from "@mui/material/FormControl";
import FormModal from "../../../components/FormModal/FormModal";
import { SmallHeightDivider, StyledButtonOutlined } from "../../../theme/Styles";
import { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Select from "../../../components/Select/Select";
import { useEffect } from "react";

const initialData = {
    value: "",
    label: "Selecciona una empresa",
};
export default function DialogSelect({ open, setOpen, title, data, onClick, setValue, value }) {
    const [request, setRequest] = useState("Personal");
    const [companyList, setCompanyList] = useState([]);

    const handleChangeRequest = (event) => {
        setRequest(event.target.value);
    };

    const handleClose = (reason) => {
        setOpen(false);
    };

    const handleChangeCompanyType = (value) => {
        setValue(value.target.value);
    };

    useEffect(() => {
        if (data) {
            const reducedArray = data.reduce((acc, curr) => {
                if (!acc.some((item) => item.value === curr.citizen)) {
                    acc.push({ value: `${curr.id},${curr.company_name}`, label: curr.company_name });
                }
                return acc;
            }, []);
            setCompanyList(() => [initialData, ...reducedArray]);
        }
    }, [data]);

    return (
        <div>
            <FormModal conditionalClose={true} open={open} onClose={handleClose} title={title} minHeight="200px">
                <SmallHeightDivider />
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={request}
                    onChange={handleChangeRequest}
                >
                    <FormControlLabel value="Personal" control={<Radio />} label="Continuar como personal" />
                    <FormControlLabel value="Empresa" disabled={data.length === 0} control={<Radio />} label="Continuar como empresa" />
                </RadioGroup>
                <FormControl sx={{ m: 1, minWidth: "96%", marginTop: 1 }}>
                    {request === "Empresa" ? (
                        <Select id="selectCompany" data={companyList} value={value} onChange={handleChangeCompanyType} disableEmptyValue />
                    ) : null}
                </FormControl>

                <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: 20 }}>
                    <StyledButtonOutlined
                        disabled={request !== "Personal" ? (value ? false : true) : false}
                        onClick={onClick}
                        variant="outlined"
                        width="30%"
                    >
                        OK
                    </StyledButtonOutlined>
                </div>
            </FormModal>
        </div>
    );
}
