import { useFormik } from "formik";
import TextField from "../../../../../components/TextField/TextField";
import {
  MediumHeightDivider,
  SmallHeightDivider,
  StyledButtonOutlined,
  Row,
} from "../../../../../theme/Styles";
import FormModal from "../../../../../components/FormModal/FormModal";
import { ButtonContainer } from "../styles/CompanyStyles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import COLORS from "../../../../../theme/Colors";

function DialogSearch({
  claimModalVisible,
  handleClaimModalVisibility,
  formik,
  searchUser,
  deleteFoundUser,
  onClick,
  title,
  sendButtonLabel,
}) {
  return (
    <FormModal conditionalClose={true} open={claimModalVisible} onClose={handleClaimModalVisibility} title={title}>
      <SmallHeightDivider />

      <Row style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }}>
        <div style={{ width: "64%" }}>
          <TextField
            id="citizen_id"
            title="Buscar por cédula o pasaporte"
            mask="99999999999999999999"
            value={formik.values.citizen_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.citizen_id && Boolean(formik.errors.citizen_id)}
            helperText={formik.touched.citizen_id && formik.errors.citizen_id}
            required
          />
        </div>
        <div style={{ width: "30%", paddingTop: 12 }}>
          <StyledButtonOutlined onClick={formik.handleSubmit} variant="outlined">
            Buscar
          </StyledButtonOutlined>
        </div>
      </Row>

      {!searchUser ? null : (
        <div>
          <Card sx={{ maxWidth: "100%" }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: COLORS.primary }} aria-label="recipe">
                  {searchUser.name.substring(0, 1)}
                </Avatar>
              }
              action={
                <IconButton aria-label="settings">
                  <CloseIcon onClick={deleteFoundUser} />
                </IconButton>
              }
              title={`${searchUser.name} ${searchUser.first_last_name}`}
              subheader={searchUser.citizen_id}
            />
          </Card>
        </div>
      )}
      <MediumHeightDivider />
      <MediumHeightDivider />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <ButtonContainer>
          <StyledButtonOutlined disabled={searchUser === null} onClick={onClick} variant="outlined">
            {sendButtonLabel}
          </StyledButtonOutlined>
        </ButtonContainer>
      </div>
    </FormModal>
  );
}

export default DialogSearch;
