import { Autocomplete } from "@mui/material";
import { Fragment, memo } from "react";
import COLORS from "../../theme/Colors";
import { FieldTitle, FieldTitleFilter, Row, StyledTextInput } from "../../theme/Styles";
import { Container, MenuContainer, StyledOption } from "./styles/SelectStyles";

function Select({
    id,
    title,
    data,
    placeholder,
    helperText = " ",
    value,
    onChange,
    onBlur,
    error,
    required,
    disabled,
    search = false,
    disableEmptyValue = false,
    selectIsInsideModal = false,
    marginRight = 0,
}) {
    let objectValue = value === undefined ? null : data?.find((item) => item.value === value);
    return (
        <Container
            sx={{
                marginRight: { marginRight },
                minWidth: "150px",
            }}
        >
            <Row>
                <FieldTitleFilter>{title} </FieldTitleFilter>
                {required ? (
                    <Fragment>
                        <FieldTitle style={{ color: COLORS.red }}>*</FieldTitle>
                    </Fragment>
                ) : null}
            </Row>
            {search ? (
                <Autocomplete
                    disablePortal
                    fullWidth
                    disableClearable
                    select
                    unselectable
                    noOptionsText="No hay opciones"
                    id={id}
                    name={id}
                    placeholder={placeholder}
                    value={objectValue}
                    disabled={disabled}
                    options={data}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) =>
                        onChange &&
                        onChange({
                            target: {
                                id: id,
                                value: newValue.value,
                            },
                        })
                    }
                    PaperComponent={selectIsInsideModal ? MenuContainer : null}
                    ListboxProps={{ style: { margin: 0 } }}
                    /* onBlur={(e,newValue) => onBlur && onBlur({
                                target:{
                                    id:id,
                                    value:newValue.value
                                }
                            })}*/
                    renderInput={(params) => (
                        <StyledTextInput
                            {...params}
                            InputProps={{ ...params.InputProps, style: { padding: 3 } }}
                            helperText={helperText}
                            FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
                            error={error}
                        />
                    )}
                />
            ) : (
                <StyledTextInput
                    fullWidth
                    id={id}
                    name={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={helperText}
                    FormHelperTextProps={{ sx: { fontSize: "0.90rem" } }}
                    error={error}
                    select
                    disabled={disabled}
                    SelectProps={{
                        native: true, //for use option tag instead of MenuItem
                    }}
                >
                    {!disableEmptyValue && <option unselectable={true} />}

                    {data?.map(
                        (
                            option //use option or StyledOption instead of MenuItem because this tag has an bug
                        ) => (
                            <StyledOption key={option.value} value={option.value}>
                                {option.label}
                            </StyledOption>
                        )
                    )}
                </StyledTextInput>
            )}
        </Container>
    );
}

export default memo(Select);
