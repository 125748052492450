import apiCall from '../services/ApiServerCall';
import apiServiceCall from '../services/ApiServiceCall';

export const cedulaValidationService = (cedula, document_type) => {
    return apiServiceCall()
        .get(`/validate/${cedula}/${document_type}`)
        .then(({ data }) => data);
};

export const cedulaValidationServiceFormulary = (cedula) => {
    return apiServiceCall()
        .get(`/jce/validate/${cedula}`)
        .then(({ data }) => data);
};

export const validationNameWithIdentificationID = (body) => {
    return apiCall()
        .post(`check/rnc/and/first_name/not/registered`, body)
        .then(({ data }) => data);
};

export const validateEmail = (email) => {
    return apiCall()
        .get(`/validate/email/${email}`)
        .then(({ data }) => data);
};

export const validatePhone = (phone) => {
    return apiCall()
        .get(`/validate/company/phone/exist/${phone}`)
        .then(({ data }) => data);
};

export const mimarenaValidationService = (noRecibo) => {
    return apiServiceCall()
        .get(`/mimarena/validate/${noRecibo}`)
        .then(({ data }) => data);
};

export const dppValidationService = (requestCode, type) => {
    //tipo="Definitiva" o "Provisional"
    return apiServiceCall()
        .get(`/dpp/validate/${requestCode}?tipo=${type}`)
        .then(({ data }) => data);
};

export const listadosValidationService = (resolution) => {
    return apiServiceCall()
        .get(`/confotur/listados/validate/${resolution}`)
        .then(({ data }) => data);
};

export const recursoReconsideracionClasificacionValidationService = (solicitud) => {
    return apiServiceCall()
        .get(`/consfotur/clasificacion/reconsideracion/validate/${solicitud}`)
        .then(({ data }) => data);
};

export const prorrogaListadosValidationService = (solicitud) => {
    return apiServiceCall()
        .get(`/consfotur/${solicitud}/validation/prorroga-listados`)
        .then(({ data }) => data);
};

export const prorrogaNoObjecionUsoDeSueloService = (solicitud) => {
    return apiServiceCall()
        .get(`/dpp/noobjecion/${solicitud}/validation/prorroga`)
        .then(({ data }) => data);
};
