import apiServiceCall from "../services/ApiServiceCall";

//TO DO ADD STATUS
export const getRequestedServices = (citizenID, page, status) => {
    return apiServiceCall()
        .get(`/requests/extras/user/${citizenID}?page=${page}&status=${status}&type=1`)
        .then(({ data }) => data);
};

export const getRequestedServicesCitizenIDWithOutCompany = (citizenID, page, status, name) => {
    return apiServiceCall()
        .get(`/all/my/requests/without/company/${citizenID}?page=${page}&name=${name}&status=${status}&type=1`)
        .then(({ data }) => data);
};

export const getRequestedServicesCitizenIDWithAllCompany = (citizenID, page, status, name, docIdentification, companyId) => {
    return apiServiceCall()
        .get(
            `/all/my/requests/with/company/${citizenID}?page=${page}&name=${name}&docIdentification=${docIdentification}&companyId=${companyId}&status=${status}&type=1`
        )
        .then(({ data }) => data);
};

export const getRequestedServicesCitizenIDWithAllCompanyMembers = (citizenID) => {
    return apiServiceCall()
        .get(`/all/my/requests/with/company/${citizenID}?page=&name=&status=1&type=1&get_only_companies_details=true`)
        .then(({ data }) => data);
};

export const getAllRequestedServicesByCompanyIDWithFilters = (companyID, page, status, name, docIdentification, citizenId) => {
    return apiServiceCall()
        .get(
            `/requests/by/company_id/${companyID}?page=${page}&name=${name}&docIdentification=${docIdentification}&status=${status}&type=1&citizen_id=${citizenId}`
        )
        .then(({ data }) => data);
};
