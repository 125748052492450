import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
    addNewMemberByCompanyID,
    deleteRepresentativeAndApplicantByCompany,
    getAllMembersByCompanyID,
    searchUserByCitizenId,
} from "../../../../../api/myCompany";
import { StyledCloseIcon } from "../../../../../components/DocumentsOfRequestsCard/styles/DocumentsOfRequestsCardStyles";
import Table from "../../../../../components/Table/Table";
import { ROLE_TYPE } from "../../../../../constants/constants";
import COLORS from "../../../../../theme/Colors";
import { MediumHeightDivider, SmallHeightDivider, StyledButtonOutlined } from "../../../../../theme/Styles";
import { SectionTitle } from "../../../styles/MyDeskStyles";
import { columns, columnsCompanyApplicant, FormCompanySendInviteSchema } from "../CompanyConstants";
import { ButtonContainer } from "../styles/CompanyStyles";
import DetailCompany from "./DetailCompany";
import DialogSelect from "./DialogSearch";

export default function ModalListMemberByCompany({
    isModalListMembersVisible,
    setIsModalListMemberVisible,
    selectedCompany = null,
    companyID,
    detailCompanyActive = true,
    dialogSelectActive = true,
}) {
    const queryClient = useQueryClient();

    const [currentPage, setCurrentPage] = useState(1);
    const { data: usersMembers } = useQuery(
        ["allMembersData", currentPage],
        () => getAllMembersByCompanyID(selectedCompany?.id ?? companyID),
        {
            cacheTime: 0,
            staleTime: 0,
        }
    );
    const [searchUser, setSearchUser] = useState(null);
    const { rolBase } = useSelector((state) => state.authReducer);

    const [modalInviteVisible, setModalInviteVisible] = useState(false);
    const mutationDeleteApplicant = useMutation(deleteRepresentativeAndApplicantByCompany);
    const mutationSearchUserById = useMutation(searchUserByCitizenId);
    const mutationAddMember = useMutation(addNewMemberByCompanyID);

    const handleCompanySelect = (company) => {
        setIsModalListMemberVisible(true);
    };

    const handleModalListMemberVisible = () => {
        setIsModalListMemberVisible(false);
    };

    const handleDeleteApplicantByCompany = (data) => {
        Swal.fire({
            title: "¿Estas seguro?",
            text: `Eliminarás al miembro ${data.citizen}!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: COLORS.secondary,
            cancelButtonColor: COLORS.primary,
            confirmButtonText: "Si, borralo!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                mutationDeleteApplicant.mutate(
                    { member_rol: ROLE_TYPE.APPLICANT, citizen_id: data.citizen, company_id: selectedCompany?.id ?? companyID },
                    {
                        onSuccess: (data) => {
                            if (data.success) {
                                Swal.fire({
                                    title: "Información",
                                    text: data.msg,
                                    icon: "info",
                                });
                                // refresh cache of allMembersData
                                queryClient.invalidateQueries("allMembersData");
                                queryClient.invalidateQueries("allMembers");
                            } else {
                                Swal.fire({
                                    title: "Información",
                                    text: data.msg,
                                    icon: "info",
                                });
                            }
                        },
                    }
                );
            }
        });
    };

    const handleModalAddMemberVisible = () => {
        sendInviteFormik.resetForm();
        setSearchUser(undefined);
        setModalInviteVisible(!modalInviteVisible);
    };

    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const sendInviteFormik = useFormik({
        initialValues: {
            citizen_id: "",
        },
        validationSchema: FormCompanySendInviteSchema,
        onSubmit: (values) => {
            searchUserById(values.citizen_id);
        },
    });

    const searchUserById = (formData) => {
        mutationSearchUserById.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    setSearchUser(data.payload);
                    // refresh cache of searchUserId
                    queryClient.invalidateQueries("searchUserId");
                } else {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "info",
                    });
                }
            },
        });
    };

    const deleteFoundUser = () => {
        setSearchUser(null);
        sendInviteFormik.resetForm();
    };

    const handleAddMember = () => {
        addMember({ citizen_id: searchUser.citizen_id, company_id: selectedCompany.id });
    };

    const addMember = (formData) => {
        mutationAddMember.mutate(formData, {
            onSuccess: (data) => {
                if (data.success) {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            queryClient.invalidateQueries("allMembersData");
                            queryClient.invalidateQueries("allMembers");
                            setSearchUser(undefined);
                            handleModalAddMemberVisible();
                            sendInviteFormik.resetForm();
                        }
                    });
                } else {
                    Swal.fire({
                        title: "Información",
                        text: data.msg,
                        icon: "info",
                    });
                }
            },
        });
    };

    return (
        <Dialog
            open={isModalListMembersVisible}
            onClose={handleModalListMemberVisible}
            TransitionComponent={Slide}
            disableEscapeKeyDown
            fullWidth
            maxWidth="xl"
        >
            <IconButton
                onClick={handleModalListMemberVisible}
                sx={{
                    display: "flex",
                    position: "absolute",
                    backgroundColor: "#FFF",
                    alignSelf: "flex-end",
                    marginTop: "10px",
                    marginRight: "20px",
                }}
            >
                <StyledCloseIcon />
            </IconButton>

            <DialogContent dividers>
                {detailCompanyActive && (
                    <>
                        <DetailCompany selectedCompany={selectedCompany} />
                        <SmallHeightDivider />
                        <ButtonContainer>
                            <StyledButtonOutlined onClick={handleModalAddMemberVisible} variant="outlined">
                                INVITAR MIEMBRO
                            </StyledButtonOutlined>
                            <MediumHeightDivider />
                        </ButtonContainer>
                    </>
                )}
                <SectionTitle style={{ color: COLORS.primary, alignSelf: " flex-start" }}>Listado de miembros:</SectionTitle>

                <Table
                    rows={usersMembers?.payload ?? []}
                    columns={rolBase === ROLE_TYPE.COMPANY ? columnsCompanyApplicant : columns}
                    onSelect={handleCompanySelect}
                    onDelete={handleDeleteApplicantByCompany}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    withoutRecord="En estos momento no existe miembros asociados a esta empresa."
                />
                {dialogSelectActive && (
                    <DialogSelect
                        claimModalVisible={modalInviteVisible}
                        handleClaimModalVisibility={handleModalAddMemberVisible}
                        formik={sendInviteFormik}
                        searchUser={searchUser}
                        deleteFoundUser={deleteFoundUser}
                        onClick={handleAddMember}
                        title="Agregar miembro"
                        sendButtonLabel={"ENVIAR"}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
