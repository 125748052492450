import { Redirect } from 'react-router';
import AcceptMembership from '../views/AcceptMembership/AcceptMembership';
import AllServices from '../views/AllServices/AllServices';
import ActivateAccount from '../views/Auth/ActivateAccount/ActivateAccount';
import Login from '../views/Auth/Login/Login';
import Register from '../views/Auth/Register/Register';
import RequestPassword from '../views/Auth/RequestPassword/RequestPassword';
import Contact from '../views/Contact/Contact';
import GeneralQueries from '../views/GeneralQueries/GeneralQueries';
import Home from '../views/Home/Home';
import ListOfServicesPerCategory from '../views/ListOfServicesPerCategory/ListOfServicesPerCategory';
import MobileValidation from '../views/MobileValidation/MobileValidation';
import { MyConfiguration } from '../views/MyConfiguration/MyConfiguration';
import MyDesk from '../views/MyDesk/MyDesk';
import { PolicyPrivacy } from '../views/PolicyPrivacy/PolicyPrivacy';
import RequestService from '../views/RequestService/RequestService';
import ServiceDescription from '../views/ServiceDescription/ServiceDescription';
import ServiceRequestedDetails from '../views/ServiceRequestedDetails/ServiceRequestedDetails';
import ValidatePayment from '../views/ValidatePayment/ValidatePayment';

const routes = [
    //PUT ALL loginRequired:false OBJECTS ROUTES on TOP of array
    {
        path: '/',
        component: Home,
        exact: true,
        layout: '/public',
        loginRequired: false
    },
    {
        path: '/login',
        component: Login,
        exact: true,
        layout: '/public',
        loginRequired: false
    },
    {
        path: '/requestPassword',
        component: RequestPassword,
        exact: true,
        layout: '/public',
        loginRequired: false
    },
    {
        path: '/register',
        component: Register,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/mobileValidation',
        component: MobileValidation,
        exact: true,
        layout: '/public',
        loginRequired: false
    },
    /* 
   {  // FOR SEE ALL FORM FIELDS AND COMPONENTS ONLY DEVELOPMENT
     path: "/TESTFORM",
     component: ExampleAppForm,
     exact: true,
     layout: "/app",
     loginRequired: false,
   },
  */
    {
        path: '/activateAccount/:activationToken',
        component: ActivateAccount,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/serviceDescription/:serviceID',
        component: ServiceDescription,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/listOfServices/:categoryID',
        component: ListOfServicesPerCategory,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/allServices',
        component: AllServices,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/acceptMembership',
        component: AcceptMembership,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/contact',
        component: Contact,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/generalQueries',
        component: GeneralQueries,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/policy',
        component: PolicyPrivacy,
        exact: true,
        layout: '/app',
        loginRequired: false
    },
    {
        path: '/myDesk',
        component: MyDesk,
        exact: true,
        layout: '/app',
        loginRequired: true
    },

    {
        path: '/myConfiguration',
        component: MyConfiguration,
        exact: true,
        layout: '/app',
        loginRequired: true
    },
    {
        path: '/requestService/:serviceID/:companyID?/:companyName?',
        component: RequestService,
        exact: true,
        layout: '/app',
        loginRequired: true
    },
    {
        path: '/serviceRequestedDetails/:requestID/:docIdentification?/:userRolCompany?/:statusID?/:companyID?/:companyName?',
        component: ServiceRequestedDetails,
        exact: true,
        layout: '/app',
        loginRequired: true
    },
    /* {
     path: "/requestedServicesList/:servicesStatus",
     component: RequestedServiceList,
     exact: true,
     layout: "/app",
     loginRequired: true,
   },
   */
    {
        path: '/validatePayment/:requestID',
        component: ValidatePayment,
        exact: false,
        layout: '/app',
        loginRequired: false
    },

    {
        path: '*',
        layout: '/public',
        component: () => <Redirect to="/public" />
    },

    {
        path: '*',
        layout: '/app',
        component: () => <Redirect to="/public" /> //Can be changed for a not login required route inside app
    }
];

export default routes;
