import { Grid } from "@mui/material";
import COLORS from "../../../../../theme/Colors";
import { CardBodyText, CardBodyTitle, CardTextContainer } from "../../../../../theme/Styles";
import { SectionTitle } from "../../../styles/MyDeskStyles";
import { ProfileContainer } from "../styles/CompanyStyles";
import { stringToDominicanPhoneNumber } from "../../../../../utilities/functions/FormatterUtil";

function DetailCompany({ selectedCompany }) {
  const fielShow = [
    { label: "RNC", valor: selectedCompany.company_rnc ?? "" },
    { label: "Nombre", valor: selectedCompany.company_name ?? "" },
    { label: "Teléfono", valor: stringToDominicanPhoneNumber(selectedCompany.company_phone) ?? "" },
    // Agrega más campos aquí si es necesario
  ];
  return (
    <div>
      {/* Renderiza la tabla solo si hay una empresa seleccionada */}
      {selectedCompany && (
        <>
          <SectionTitle style={{ color: COLORS.primary }}>Empresa seleccionada</SectionTitle>

          <ProfileContainer>
            <CardTextContainer>
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 8, sm: 8, md: 12 }}
              >
                {" "}
                {fielShow.map((field, index) => (
                  <Grid item xs={4} sm={4} md={4} key={index}>
                    <CardBodyTitle>{field.label}:</CardBodyTitle>
                    <CardBodyText>{field.valor}</CardBodyText>
                  </Grid>
                ))}
                {/*   {selectedCompanyTest.rol_name == 'Admin' ? (
                                    <>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <StyledButtonOutlined variant="contained">
                                                Solicitudes
                                            </StyledButtonOutlined>
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={4}>
                                            <StyledButtonOutlined variant="contained">
                                                Miembros
                                            </StyledButtonOutlined>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <StyledButtonOutlined

                                                variant="contained"
                                            >
                                                Invitar usuario
                                            </StyledButtonOutlined>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12} sm={6} md={5}>
                                        <StyledButtonOutlined variant="contained">
                                            Usuarios a cargo
                                        </StyledButtonOutlined>
                                    </Grid>
                                )} */}
              </Grid>
            </CardTextContainer>
          </ProfileContainer>
        </>
      )}
    </div>
  );
}

export default DetailCompany;
