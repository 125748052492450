import { useState, memo } from 'react';
import IconButton from '@mui/material/IconButton';
import {
    Container,
    TextContainer,
    Text,
    StyledCloseIcon,
    StyledCheckCircleIcon,
    StyledWarningIcon,
    StyledCancelIcon,
    CloseButtonContainer,
    IconContainer,
    StyledInfoIcon,
    ActionButtonContainer
} from './styles/DeskNotificationStyles';
import Slide from 'react-reveal/Slide';
import parse from 'html-react-parser';
import { StyledButton } from '../../theme/Styles';

function DeskNotification({ variant, message, onClose, disableAnimation, disableCloseButton,showButton,buttonTitle,onButtonClick,isSelected }) {

    const [showAnimation, setShowAnimation] = useState();

    const handleOnClose = () => {
        setShowAnimation(false);
        setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 1000);
    }
    return (
        <Slide right={!disableAnimation} when={showAnimation}>
            <Container isSelected={isSelected} variant={variant}>
                <TextContainer>
                    <IconContainer>
                        {
                            variant === 'danger' ?
                                <StyledCancelIcon />
                                :
                                variant === 'warning' ?
                                    <StyledWarningIcon />
                                    :
                                    variant === 'info' ?
                                        <StyledInfoIcon />
                                        :
                                        < StyledCheckCircleIcon />


                        }
                    </IconContainer>
                        <Text >
                            {
                                message && parse(message)
                            }
                        </Text>
                </TextContainer>
                {
                    disableCloseButton ? null :
                        <CloseButtonContainer>
                            <IconButton onClick={handleOnClose} sx={{ padding: 0 }}>
                                <StyledCloseIcon />
                            </IconButton>
                        </CloseButtonContainer>
                }
                {
                    showButton &&
                        <ActionButtonContainer>
                            <StyledButton onClick={onButtonClick}>
                                {buttonTitle}
                            </StyledButton>
                        </ActionButtonContainer>
                }

            </Container>
        </Slide>
    );
}

export default memo(DeskNotification);
