export const SET_URL = "SET_URL";
export const CLEAN_URL = "CLEAN_URL";

// Si solo necesitas pasar la URL, no necesitas envolverlo en un objeto
export const SetUrl = (url) => {
    return {
        type: SET_URL,
        payload: url,
    };
};

export const CleanUrl = () => {
    return {
        type: CLEAN_URL,
    };
};
