import { useState, useLayoutEffect, useEffect } from "react";
import { SmallHeightDivider } from "../../../theme/Styles";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAppSubHeaderTitle } from "../../../redux/actions/UiActions";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FormRegisterPersonal from "./FormRegisterPersonal";
import FormRegisterCompany from "./FormRegisterCompany";

function Register() {
    const [value, setValue] = useState("1");
    const history = useHistory();
    const dispatch = useDispatch();
    const { authenticated } = useSelector((state) => state.authReducer);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useLayoutEffect(() => {
        //UPDATE APP HEADER SUBTITLE
        if (value === "1") {
            dispatch(UpdateAppSubHeaderTitle("Registro ciudadano"));
        } else {
            dispatch(UpdateAppSubHeaderTitle("Registro empresa"));
        }
    }, [dispatch, value]);

    useEffect(() => {
        if (authenticated) {
            history.push("/app/myDesk");
            return;
        }
    }, [authenticated, history]);

    return (
        <Box sx={{ typography: "body1" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", marginX: 8 }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Registro ciudadano" value="1" style={{ fontWeight: "bold", textTransform: "inherit", fontSize: 16 }} />
                        {/* <Tab label="Registro empresa" value="2" style={{ fontWeight: "bold", textTransform: "inherit", fontSize: 16 }} /> */}
                    </TabList>
                </Box>
                <SmallHeightDivider />
                <SmallHeightDivider />
                <TabPanel value="1">
                    <FormRegisterPersonal />
                </TabPanel>
                <TabPanel value="2">
                    <FormRegisterCompany />
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default Register;
