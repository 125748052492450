import { useLayoutEffect } from 'react';
import { UpdateAppSubHeaderTitle } from '../../redux/actions/UiActions';
import {
    Container,
} from './styles/MobileValidationStyles';
import GlobalLoading from '../../components/GlobalLoading/GlobalLoading'

function MobileValidation() {

    useLayoutEffect(() => {
        //UPDATE APP HEADER SUBTITLE
       // dispatch(UpdateAppSubHeaderTitle('Validando en app movil')) // TITLE OF SUBHEADER APP
    }, []);

    return (
        <Container >
            <GlobalLoading showByProp={true}  />
        </Container >
    );
}

export default MobileValidation;