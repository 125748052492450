import apiCall from "../services/ApiServerCall";
import apiServiceCall from "../services/ApiServiceCall";

export const getAllCompanies = async () => {
  return (await apiCall().get("/auth/get/getallcompany")).data;
};

export const addNewCompany = async (data) => {
  return (await apiCall().post("/auth/register/createnewcompany", data)).data;
};

export const modifyCompany = async (data) => {
  return (await apiCall().post("/auth/update/updatecompany", data)).data;
};

export const searchUserByCitizenId = async (citizenId) => {
  return (await apiCall().get(`/get/basic/user/information/${citizenId}`)).data;
};

export const sendInviteUser = async (data) => {
  const { citizenId } = data;
  return (await apiCall().get(`send/membership/invitation?citizen_id=${citizenId}`)).data;
};

export const acceptMembership = async (requestData) => {
  return (await apiCall().post("accept/membership", requestData)).data;
};

////////////////////////////////////////////////////////////////

export const getAllRepresentativeByCompany = async () => {
  return (await apiCall().get("/get/all/representatives/from/my/company")).data;
};

export const deleteRepresentativeAndApplicantByCompany = async (data) => {
  return (await apiCall().post("/delete/company/member", data)).data;
};

export const getAllRepresentativeAndApplicant = async () => {
  return (await apiCall().get("/auth/get/getallcompany")).data;
};

export const getAllMembersByCompanyID = async (id) => {
  return (await apiCall().get(`/get/all/company/members/by/company/id/${id}`)).data;
};

export const addNewMemberByCompanyID = async (data) => {
  return (await apiCall().post("/add/automatic/company/member", data)).data;
};

export const changeMember = async (data) => {
  return (await apiServiceCall().post("/change/user/request/number", data)).data;
};