import { LOGIN_SUCCESS, LOGOUT } from "../actions/AuthActions";

const initialState = {
    authenticated: false,
    profileImg: null,
    rolBase: null,
    companyID: null,
    citizenID: null,
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: action.payload.authenticated,
                profileImg: action.payload.profileImg,
                rolBase: action.payload.rolBase,
                companyID: action.payload.companyID,
                citizenID: action.payload.citizenID,
            };
        case LOGOUT:
            return {
                ...state,
                authenticated: false,
                profileImg: null,
                roleBase: null,
                companyID: null,
                citizenID: null,
            };
        default:
            return state;
    }
};

export default AuthReducer;
