import COLORS from "../../../../../theme/Colors";
import { SmallHeightDivider } from "../../../../../theme/Styles";
import { SectionTitle } from "../../../styles/MyDeskStyles";
import { SelectIconCompany } from "../CompanyConstants";
import {
  BodyText,
  Column,
  ContainerCompanies,
  ContentContainer,
  ImageLogo,
  LineDivider,
  RowContainer,
  StyledSettingIcon,
  StyledClearIcon,
} from "../styles/CompanyStyles";

function CompanyListRespresentatives({
  myRepresentatives = [],
  columns,
  title,
  withoutRecord,
  onSelect,
  onEdit,
  onDelete,
}) {
  return (
    <>
      {myRepresentatives.length !== 0 ? (
        <div>
          <SectionTitle style={{ color: COLORS.primary }}>{title}</SectionTitle>
          <SmallHeightDivider />
          <ContainerCompanies>
            <SmallHeightDivider />
            <ContentContainer>
              <RowContainer>
                {columns.map((column, index) => (
                  <Column key={index} style={{ width: "20%", fontWeight: 900 }}>
                    <BodyText>{column.label}</BodyText>
                  </Column>
                ))}
              </RowContainer>
              <SmallHeightDivider />
              {myRepresentatives?.map((representative, index) => (
                <div key={index}>
                  <RowContainer>
                    {columns.map((column, columnIndex) => (
                      <Column key={columnIndex} style={{ width: "20%" }}>
                        {!column.label.includes("Seleccionar") && !column.label.includes("Editar") && (
                          <BodyText>{representative[column.key]}</BodyText>
                        )}
                        {column.label === "Seleccionar" && (
                          <ImageLogo src={SelectIconCompany} onClick={() => onSelect(representative)} />
                        )}
                        {column.label === "Editar" && (
                          <StyledSettingIcon onClick={() => onEdit(representative)} />
                        )}
                        {column.key === "Delete" && (
                          <StyledClearIcon onClick={() => onDelete(representative.citizen_id)} />
                        )}
                      </Column>
                    ))}
                  </RowContainer>
                  <LineDivider />
                </div>
              ))}
            </ContentContainer>
            <SmallHeightDivider />
            <SmallHeightDivider />
          </ContainerCompanies>
        </div>
      ) : (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <SectionTitle>{withoutRecord}</SectionTitle>
        </div>
      )}
    </>
  );
}

export default CompanyListRespresentatives;
